import ExternalLink from '@mui/material/Link';
import PoetThemeContext from '../../Contexts/poet-theme-context.js';
import './Footer.css';

import { Link } from 'react-router-dom';
import { useContext } from 'react';

export default function Footer() {
    const theme = useContext(PoetThemeContext);

    return (
        <div
            className='Footer'
            style={{
                fontFamily: theme.mui.typography.fontFamily,
                fontSize: theme.mui.typography.fontSize,
                fontWeight: theme.mui.typography.fontWeightRegular
            }}
        >
            <ul className='Footer-list'>
                <a
                    href='https://bandwagonfanclub.com/contact'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    <li>Contact Us</li>
                </a>
                <li className='Footer-point'>•</li>
                <Link to={'/privacy'}>
                    <li>Privacy Policy</li>
                </Link>
                <li className='Footer-point'>•</li>
                <Link to={'/terms'}>
                    <li>Terms of Use</li>
                </Link>
            </ul>
            <a
                href={
                    theme.strings.footerCreditUrl ||
                    'https://bandwagonfanclub.com'
                }
                target='_blank'
                rel='noopener noreferrer'
            >
                <p className='pt-2 pb-4 font-semibold'>
                    {theme.strings.footerCreditText || 'Built by Bandwagon'}
                </p>
            </a>
            <p className='Footer-googleRecaptcha'>
                This site uses reCAPTCHA. The Google&nbsp;
                <ExternalLink href='https://policies.google.com/privacy'>
                    Privacy Policy
                </ExternalLink>{' '}
                and&nbsp;
                <ExternalLink href='https://policies.google.com/terms'>
                    Terms of Service
                </ExternalLink>{' '}
                apply.
            </p>
        </div>
    );
}
