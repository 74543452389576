import { TbVinyl } from 'react-icons/tb';
import { GiMoneyStack } from 'react-icons/gi';
import { Link } from 'react-router-dom';
import { PiChatsBold } from 'react-icons/pi';
import { FaArrowRight } from 'react-icons/fa';

import './DayOnes.css';

export default function DayOnes() {
    return (
        <div className='DayOnes'>
            <div className='DayOnes-fold DaysOnes-fold--hero'>
                <div className='DayOnes-content'>
                    <h2>The Day Ones Collection</h2>
                    <p>
                        This isn't just another platform; it's your own
                        backstage pass to connecting with your Day&nbsp;Ones and
                        setting the stage for unparalleled fan moments.
                    </p>
                </div>
                <div className='DayOnes-image'>
                    <div className='DayOnes-imageContent'>
                        <h2>The Day Ones Collection</h2>
                        <p>
                            This isn't just another platform; it's your own
                            backstage pass to connecting with your Day&nbsp;Ones
                            and setting the stage for unparalleled fan moments.
                        </p>
                    </div>
                </div>
            </div>
            <div className='DayOnes-fold DaysOnes-fold--why'>
                <h2>What's in it for you?</h2>
                <div className='DayOnes-foldWhyTileContainer'>
                    <div className='DayOnes-foldWhyTile'>
                        <div className='DayOnes-foldWhyTileIcon'>
                            <TbVinyl />
                        </div>
                        <h2>Build your day ones</h2>
                        <p>
                            Time to carve out your own corner of the internet.
                            Create a badge using POE. where you get to carve out
                            your own corner of the internet. You
                        </p>
                    </div>
                    <div className='DayOnes-foldWhyTile'>
                        <div className='DayOnes-foldWhyTileIcon'>
                            <GiMoneyStack />
                        </div>
                        <h2>Earn as you grow</h2>
                        <p>
                            Your music? Gold. Why the hell shouldn't you get
                            paid for it? Mint, drop, repeat. With every badge
                            minted by a new day one member, you'll earn some
                            cash.
                        </p>
                    </div>
                    <div className='DayOnes-foldWhyTile'>
                        <div className='DayOnes-foldWhyTileIcon'>
                            <PiChatsBold />
                        </div>
                        <h2>Engage your Fanclub</h2>
                        <p>
                            Beyond likes and streams, engage with your fans on a
                            personal level. Use TDOC to offer exclusive drops,
                            BTS looks, and merch discounts.
                        </p>
                    </div>
                </div>
            </div>
            <div className='DayOnes-fold DaysOnes-fold--contact'>
                <div className='DayOnes-foldContactButtonContainer'>
                    <a href='https://docs.google.com/forms/d/13IpAVg80FcwlULweqHfrcq4fHaPQoL8klkD7KRv4dMM'>
                        <button className='DayOnes-ctaBtn'>
                            <p>Become a featured artist</p>
                            <FaArrowRight />
                        </button>
                    </a>
                    <a href='https://docs.google.com/forms/d/e/1FAIpQLSd3XGZaiPtg3HrbXHf90vXBgVvONuScQkX9PR-p4VEq549DTA/viewform'>
                        <button className='DayOnes-ctaBtn'>
                            <p>Become a brand partner</p>
                            <FaArrowRight />
                        </button>
                    </a>
                </div>
                <div className='DayOnes-foldContactFaqContainer'>
                    <Link to='dayonesfaq' className='DayOnes-foldContactFaq'>
                        Frequently Asked Questions
                    </Link>
                </div>
            </div>
        </div>
    );
}
