import getContract from '../Requests/get-contract';
import React from 'react';
import RemoteValueProvider from './RemoteValueProvider';

export const ContractDataContext = React.createContext();

const ContractDataProvider = ({ children }) => (
    <RemoteValueProvider
        context={ContractDataContext}
        fetch={async key => {
            const { res, error } = await getContract({
                address: key
            });

            return [res.contract, [error]];
        }}
    >
        {children}
    </RemoteValueProvider>
);

export default ContractDataProvider;
