import axios from 'axios';
import lodash from 'lodash';

export default async function graphqlRequest(
    query,
    variables = {},
    options = {}
) {
    const endpoint = `${window.__RUNTIME_CONFIG__.REACT_APP_REMINISCERATOR_ENDPOINT}/graphql`;

    const remRes = await axios.post(
        endpoint,
        {
            query,
            variables
        },
        options
    );

    const res = lodash.get(remRes, 'data.data', {});
    const error =
        lodash.get(remRes, 'data.errors.0') ||
        lodash.get(remRes, 'response.data.errors.0');

    return { res, error };
}
