import lodash from 'lodash';

import { useMemo, useRef, useState } from 'react';

// Can't put this in line or we get a new reference each render and thus
// useEffect() fires with each render.
const defaultKeySerializer = k => JSON.stringify(k);

// A local cache for sharing between components what we know about server
// values. Call `update(key, value)` to merge top level value fields onto our
// existing model for a key--useful if you've learned something about an entity
// via a side channel (like we probably know a lot about a POET right after we
// mint it even without going and fetching the newly-minted POET).
//
// You probably should never use this provider directly. Use (or create) an
// instance for the specific data domain instead. PoetDataProvider is a good
// example.
//
// If you just need to track the value associated with a single key as we learn
// about it, use ../utils/use-remote-value.js.
function RemoteValueProvider({
    context,
    children,
    fetch,
    keySerializer = defaultKeySerializer
}) {
    const db = useRef({});
    const loadingMap = useRef({});
    const errorMap = useRef({});

    const [iteration, setIteration] = useState(0);

    const value = useMemo(
        () => ({
            get(key) {
                key = keySerializer(key);

                return [
                    db.current[key] || {},
                    loadingMap.current[key] || false,
                    errorMap.current[key] || []
                ];
            },

            iteration,

            async refresh(key) {
                const sKey = keySerializer(key);

                if (!loadingMap.current[sKey]) {
                    loadingMap.current[sKey] = true;
                    setIteration(i => i + 1);

                    let result, errors;

                    let tries = 0;
                    while (!result && tries < 3) {
                        try {
                            [result, errors = []] = await fetch(key);
                        } catch (e) {
                            console.log(e);
                            errors = lodash.get(e, 'response.data.errors', [e]);
                        }

                        if (!result) {
                            await new Promise(r => setTimeout(r, 3000));
                        }
                        tries++;
                    }

                    if (errors.length === 0) {
                        delete errorMap.current[sKey];
                    } else {
                        errorMap.current[sKey] = errors;
                    }

                    key = keySerializer(key);

                    db.current[key] = {
                        ...db.current[key],
                        ...result
                    };

                    delete loadingMap.current[sKey];
                    setIteration(i => i + 1);
                }
            },

            serializeKey(k) {
                return keySerializer(k);
            },

            update(key, value) {
                key = keySerializer(key);

                db.current[key] = {
                    ...db.current[key],
                    ...value
                };

                setIteration(i => i + 1);
            }
        }),
        [db, errorMap, fetch, iteration, keySerializer, loadingMap]
    );

    return <context.Provider value={value}>{children}</context.Provider>;
}

export default RemoteValueProvider;
