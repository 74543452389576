import Carousel from '../Carousel/Carousel';
import ClaimPoet from '../Utils/ClaimPoet';
import Loader from '../Loader';
import Share from '../Utils/Share';
import Tags from '../Utils/Tags';
import Text from '../MuiAdapters/Text.js';
import useRemoteValue from '../../utils/use-remote-value';

import { ContractDataContext } from '../ContractDataProvider';
import { Link as InternalLink } from 'react-router-dom';
import { Link as MuiLink } from '@mui/material';
import { PoetDataContext } from '../PoetDataProvider.js';
import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { When } from 'react-if';

import './ContractDetail.css';
import '../Carousel/Carousel.css';

function explorerHref(contractAddress) {
    return window.__RUNTIME_CONFIG__.EXPLORER_URL_TEMPLATE.replace(
        new RegExp('{{contractAddress}}', 'g'),
        contractAddress
    );
}

export default function ContractDetail(props) {
    const [contract, provisional] = useRemoteValue(
        ContractDataContext,
        useParams().address
    );

    return (
        <div className='ContractDetail'>
            <div className='ContractDetail-content'>
                {Object.keys(contract).length > 0 ? (
                    <LoadedContractDetail {...props} />
                ) : provisional ? (
                    <Loader />
                ) : (
                    <p className='ContractDetail-errorMsg'>
                        We're sorry, we couldn't find a POET at that address.
                    </p>
                )}
            </div>
        </div>
    );
}

function LinkedImage({ contract, tokenId }) {
    const [nftData, nftDataProvisional] = useRemoteValue(
        PoetDataContext,
        useMemo(
            () => ({
                contract: contract.address,
                tokenId: tokenId
            }),
            [contract.address, tokenId]
        )
    );

    if (nftData.metadata) {
        nftData.parsedMetadata = JSON.parse(nftData.metadata);
        nftData.parsedMetadata.tags = nftData.parsedMetadata.tags || [];
    }
    const image = nftData?.parsedMetadata?.image;

    return (
        <InternalLink
            key={tokenId}
            to={`/poet/${contract.address}/token/${tokenId}`}
            className='Carousel-link'
        >
            <img src={image} className='Carousel-slideContent' />
        </InternalLink>
    );
}

function LoadedContractDetail({ sections = ['claim', 'share'], title }) {
    const contractAddress = useParams().address;
    const [contract] = useRemoteValue(ContractDataContext, contractAddress);
    const parsedMetadata = JSON.parse(contract.metadataJson || '{}');
    parsedMetadata.tags = parsedMetadata.tags || [];

    const { totalSupply, totalClaimed } = contract;
    const claimable =
        totalSupply && totalSupply !== totalClaimed && contract.metadataUri;
    const canShare = claimable || contract.numTokens !== undefined;
    const numTokens = contract.numTokens || 0;

    const history = useHistory();
    const name = parsedMetadata.name || contract.name || '(Untitled)';

    const images = [];
    for (let i = 1; i <= numTokens; i++) {
        images.push(<LinkedImage contract={contract} tokenId={i} />);
    }

    return (
        <>
            <Text className='ContractDetail-pageTitle' variant='h1'>
                {title}
            </Text>
            <Text className='ContractDetail-nftName' variant='poetTitle'>
                {name}
            </Text>
            {contract.numTokens === undefined || contract.numTokens === 1 ? (
                <img
                    className='ContractDetail-nftImage'
                    src={parsedMetadata.image}
                    alt={name}
                />
            ) : (
                <Carousel>{images}</Carousel>
            )}
            <Text
                className='ContractDetail-nftDescription'
                variant='poetDescription'
            >
                {parsedMetadata.description || ''}
            </Text>
            <When condition={parsedMetadata.tags.length > 0}>
                <Tags tab='created' tags={parsedMetadata.tags} />
            </When>
            {sections.includes('share') && canShare && (
                <Share
                    poetName={name}
                    url={`${window.location.href.replace('/share', '')}/claim`}
                />
            )}
            {contract.numTokens === undefined && sections.includes('claim') && (
                <ClaimPoet
                    claimedCt={contract.totalClaimed}
                    claimable={claimable}
                    contractAddress={contractAddress}
                    onSuccess={tokenId =>
                        history.push(
                            `/poet/${contractAddress}/token/${tokenId}?claim=successful`
                        )
                    }
                    soldOut={totalSupply === totalClaimed}
                    supplyCt={contract.totalSupply}
                />
            )}
            <div className='ContractDetail-scanLink'>
                <MuiLink
                    href={explorerHref(contractAddress)}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='link'
                >
                    {window.__RUNTIME_CONFIG__.EXPLORER_LINK_TEXT}
                </MuiLink>
            </div>
        </>
    );
}
