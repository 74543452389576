import './Share.css';

import { Button } from '@mui/material';
import { Link as LinkIcon, SaveAlt } from '@mui/icons-material';
import { useEffect, useState, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react';

export default function Share({ poetName, url }) {
    const [claimUrlHasBeenCopied, setClaimUrlHasBeenCopied] = useState(false);

    useEffect(() => {
        const resetClaimUrlHasBeenCopied = setTimeout(() => {
            setClaimUrlHasBeenCopied(false);
        }, 3000);
        return () => clearTimeout(resetClaimUrlHasBeenCopied);
    }, [claimUrlHasBeenCopied]);

    const qrCodeRef = useRef();
    return (
        <div className='Share'>
            <div className='Share-qr' ref={qrCodeRef}>
                <QRCodeCanvas id='Share-qrCodeCanvas' value={url} size={144} />
            </div>
            <div className='Share-buttons'>
                <Button
                    className='Share-downloadQr'
                    onClick={() => {
                        const canvas =
                            document.getElementById('Share-qrCodeCanvas');
                        const pngUrl = canvas
                            .toDataURL('image/png')
                            .replace('image/png', 'image/octet-stream');
                        let downloadLink = document.createElement('a');
                        downloadLink.href = pngUrl;
                        downloadLink.download = `Claim your poet - ${poetName}.png`;
                        document.body.appendChild(downloadLink);
                        downloadLink.click();
                        document.body.removeChild(downloadLink);
                    }}
                    variant='text'
                >
                    <SaveAlt />
                </Button>
                <Button
                    className='Share-copyShareLinkBtn'
                    onClick={async () => {
                        navigator.clipboard.writeText(url);
                        setClaimUrlHasBeenCopied(true);
                    }}
                    variant='text'
                >
                    {claimUrlHasBeenCopied ? 'Copied!' : <LinkIcon />}
                </Button>
            </div>
        </div>
    );
}
