import RemWalletContext from '../../Contexts/rem-wallet-context';

import { Link as InternalLink } from 'react-router-dom';
import { Typography as Font } from '@mui/material';
import { useContext } from 'react';

import './Tags.css';

export default function Tags({ tab = 'claimed', tags }) {
    const wallet = useContext(RemWalletContext);

    return (
        <div className='Tags'>
            {tags.map(tag => tagLink(tag, wallet.urlWallet, tab))}
        </div>
    );
}

function tagLink(tag, wallet, tab) {
    return (
        <div key={tag} className='Tags-tag'>
            <Font className='Tags-link' variant='poetTags' component='p'>
                <InternalLink to={`/wallet/${wallet}?tags=${tag}#${tab}`}>
                    {`#${tag}`}
                </InternalLink>
            </Font>
        </div>
    );
}
