import ContractDetail from './ContractDetail/ContractDetail';
import DayOnes from './Pages/DayOnes/DayOnes.jsx';
import DayOnesFaq from './Pages/DayOnes/Faq.jsx';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Homepage from './Pages/Homepage/Homepage';
import Learn from './Learn/Learn';
import New from './New/New';
import PoetDetail from './PoetDetail/PoetDetail';
import PoetThemeContext from '../Contexts/poet-theme-context';
import PrivacyPolicy from './PrivacyPolicy/PrivacyPolicy';
import ReCAPTCHAWidget from 'react-google-recaptcha';
import RemWalletProvider from './RemWalletProvider.js';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import Wallet from './Wallet/Wallet';

import { Container, Paper } from '@mui/material';
import { createContext, useContext, useRef } from 'react';
import { Helmet } from 'react-helmet';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useLocation
} from 'react-router-dom';
import { If, Then, Else } from 'react-if';

import './App.css';

const title = 'POETs by Bandwagon';
const description = `A POET is a Proof of Experience Token that can document
  and represent the most important and interesting moments of your life.
  From everyday moments like dinner with friends to life-changing
  experiences like traveling the world, you can preserve and share these
  moments, memories, and experiences as NFTs (non-fungible tokens).
  Whether you're creating an NFT just for yourself — a one of one — or
  you're commemorating a weekend with friends or a large event where the
  maximum supply is in the hundreds, creating your POET is easy,
  fast, and free. All you need to get started is your crypto wallet and
  we'll help you capture, preserve, and share your experience on an
  energy-efficient blockchain.`;

export const ReCAPTCHAContext = createContext();

// Outermost at the top, innermost at the bottom.
const wrappers = [
    x => <ReCAPTCHA>{x}</ReCAPTCHA>,
    x => <Router>{x}</Router>,
    x => <RemWalletProvider>{x}</RemWalletProvider>
];

function App() {
    let app = <WrappedApp />;

    for (let i = wrappers.length - 1; i >= 0; i--) {
        app = wrappers[i](app);
    }

    return app;
}

// Header benefits from router context, so we bundle it with the child content.
function Page({ children, showHeaderCreate = true }) {
    return (
        <>
            <Header showCreate={showHeaderCreate} />
            <Container>
                <div className='md:p-5 max-w-6xl z-10'>{children}</div>
            </Container>
        </>
    );
}

function ReCAPTCHA({ children }) {
    const ref = useRef();

    return (
        <ReCAPTCHAContext.Provider
            value={{
                executeAsync: async () => ref.current.executeAsync()
            }}
        >
            {children}
            <ReCAPTCHAWidget
                ref={ref}
                size='invisible'
                sitekey={window.__RUNTIME_CONFIG__.REACT_APP_RECAPTCHA_SITE_KEY}
            />
        </ReCAPTCHAContext.Provider>
    );
}

function WrappedApp() {
    const location = useLocation();
    const theme = useContext(PoetThemeContext);

    return (
        <>
            {
                // Just from experimentation, trying to put a conditional into a
                // Helmet block seems to confuse Helmet. But since Helmet blocks
                // stack, a conditional Helmet block works just fine.
                theme.features.googleAnalyticsConfig && (
                    <Helmet>
                        <script
                            async
                            src={`https://www.googletagmanager.com/gtag/js?id=${theme.features.googleAnalyticsConfig}`}
                        ></script>
                        <script>{`
                          window.dataLayer = window.dataLayer || [];
                          function gtag(){dataLayer.push(arguments);}
                          gtag('js', new Date());
                          gtag('config', '${theme.features.googleAnalyticsConfig}');
                        `}</script>
                    </Helmet>
                )
            }
            <Helmet>
                <title>{theme.strings.tabTitle || 'POETs by Bandwagon'}</title>
                <meta property='og:title' content={title} />
                <meta property='og:description' content={description} />
                <meta property='og:type' content='website' />
                <meta
                    property='og:image'
                    content={theme.assets.FullSizeLogoImage}
                />
                <meta property='og:url' content={window.location.href} />
                <meta name='twitter:card' content='summary' />
                <meta name='twitter:site' content='@builtxbandwagon' />
                <meta name='twitter:creator' content='@builtxbandwagon' />
                <meta name='twitter:title' content={title} />
                <meta name='twitter:description' content={description} />
                <meta
                    name='twitter:image'
                    content={theme.assets.FullSizeLogoImage}
                />

                {theme.headLinks.map((attributes, i) => (
                    <link key={`themeHeadLink${i}`} {...attributes} />
                ))}
            </Helmet>
            <Paper elevation={0} square={true} className='App'>
                <Switch>
                    <Route path='/learn'>
                        <Page>
                            <Learn />
                        </Page>
                    </Route>
                    <Route path='/privacy'>
                        <Page>
                            <PrivacyPolicy />
                        </Page>
                    </Route>
                    <Route path='/terms'>
                        <Page>
                            <TermsAndConditions />
                        </Page>
                    </Route>
                    <Route path='/poet/:address/share'>
                        <Page>
                            <ContractDetail title='Congrats on your POET!' />
                        </Page>
                    </Route>
                    <Route path='/poet/:address/claim'>
                        <Page>
                            <ContractDetail
                                title='Claim your POET'
                                sections={['claim']}
                            />
                        </Page>
                    </Route>
                    <Route path='/poet/:address/token/:tokenId'>
                        {
                            // Note we can't use <If> here, react-dom-router
                            // is picky and will consider our multiple Routes
                            // to <Homepage/> as "different" is they aren't
                            // at the same level of JSX, causing undesirable
                            // reloads when the URL changes
                            theme.features.homepage &&
                            location?.state?.fromHomepage ? (
                                <>
                                    <Header />
                                    <Homepage />
                                </>
                            ) : (
                                <Page>
                                    <PoetDetail />
                                </Page>
                            )
                        }
                    </Route>
                    <Route path='/wallet/:wallet'>
                        <Page>
                            <Wallet />
                        </Page>
                    </Route>
                    <Route path='/create'>
                        <Page showHeaderCreate={false}>
                            <New />
                        </Page>
                    </Route>
                    <Route path='/dayones'>
                        <Header />
                        <DayOnes />
                    </Route>
                    <Route path='/dayonesfaq'>
                        <Page>
                            <DayOnesFaq />
                        </Page>
                    </Route>
                    <Route path='/'>
                        {
                            // Note we can't use <If> here, react-dom-router
                            // is picky and will consider our multiple Routes
                            // to <Homepage/> as "different" is they aren't
                            // at the same level of JSX, causing undesirable
                            // reloads when the URL changes
                            theme.features.homepage ? (
                                <>
                                    <Header />
                                    <Homepage />
                                </>
                            ) : (
                                <Page showHeaderCreate={false}>
                                    <New />
                                </Page>
                            )
                        }
                    </Route>
                </Switch>
                <Footer />
            </Paper>
        </>
    );
}

export default App;
