import { providers } from 'ethers';
import { useState, useEffect } from 'react';

const provider = new providers.InfuraProvider(
    window.__RUNTIME_CONFIG__.REACT_APP_INFURA_NETWORK,
    window.__RUNTIME_CONFIG__.REACT_APP_INFURA_ID
);

export default function useDisplayedAddress(rawAddress) {
    const [ensName, setEnsName] = useState(
        rawAddress?.endsWith('.eth') ? rawAddress : undefined
    );

    // Kick off a reverse ENS lookup whenever the raw address changes.
    useEffect(() => {
        if (rawAddress && !rawAddress.endsWith('.eth')) {
            provider
                .lookupAddress(rawAddress)
                .then(address => setEnsName(address))
                .catch(e => 'Error resolving ens name: ' + e);
        }
    }, [rawAddress]);

    return ensName ?? shortenedAddress(rawAddress) ?? '(Unknown wallet)';
}

function shortenedAddress(a) {
    return a && a.slice(0, 6) + '...' + a.slice(-6);
}
