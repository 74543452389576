import './PoetTile.css';

import * as React from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, Typography as Font } from '@mui/material';
import { Filter } from '@mui/icons-material';
import PoetThemeContext from '../../Contexts/poet-theme-context';
import ThemeProvider from '../ThemeProvider.js';

export default function PoetTile(props) {
    const theme = useContext(PoetThemeContext);
    const image = (
        <img className='PoetTile-image' src={props.image} alt={props.name} />
    );
    const name = props.tokenId ? `${props.name} #${props.tokenId}` : props.name;
    const imageLink = !props.isLink ? (
        image
    ) : (
        <Link
            to={
                props.link ||
                `/poet/${props.contractAddress}/token/${props.tokenId}`
            }
        >
            {props.isAlbum && (
                <Filter className='PoetTile-albumIcon' color='secondary' />
            )}
            {image}
        </Link>
    );

    return (
        <ThemeProvider.Subtheme className='PoetTile' themeName='PoetTile'>
            <Card className='PoetTile-card'>
                {imageLink}

                <div
                    className='PoetTile-info'
                    style={{
                        backgroundColor: theme.mui.palette.background.poet,
                        color: theme.mui.palette.text.poet
                    }}
                >
                    <Font
                        variant='poetTitle'
                        component='span'
                        className='PoetTile-title'
                    >
                        {name}
                    </Font>
                    {props.tags && props.tags.length > 0 ? (
                        <Font
                            variant='poetTags'
                            component='span'
                            className='PoetTile-tags'
                        >
                            {props.tags.map(tag => (
                                <a
                                    className='inline whitespace-pre cursor-pointer'
                                    onClick={() => props.handleTagClick(tag)}
                                >
                                    {`#${tag} `}
                                </a>
                            ))}
                        </Font>
                    ) : (
                        <Font
                            variant='poetTags'
                            component='span'
                            className='PoetTile-noTags'
                        >
                            No tags given
                        </Font>
                    )}
                </div>
            </Card>
        </ThemeProvider.Subtheme>
    );
}
