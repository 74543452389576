import axios from 'axios';
import clone from 'clone';
import Color from 'color';
import extend from 'deep-extend';
import { get, set } from 'lodash';
import PoetThemeContext from '../Contexts/poet-theme-context.js';

import { Box } from '@mui/material';

import {
    createTheme,
    ThemeProvider as MuiThemeProvider
} from '@mui/material/styles';
import { useContext, useEffect, useMemo, useState } from 'react';

// This file lets us do sophisticated pre-processing on tenant themes before
// they actually get passed along to MUI and other subsystems. We provide a
// few notable pieces of flexibility during this pre-processing stage to aid
// tenant theme creation:
//
// * Any object in the specified theme with an
//   '$inherit': 'lodash.path.to.value' field will be replaced with the
//   specified value from the final theme. If that value is an object, then the
//   inheriting object will be merged over top of it. I.e.:
//
//   {
//       "foo": { "$inherit": "bar.bazz", "a": "a1", "b": "b1" },
//       "bar": { "bazz": { "b": "b2", "c": "c2" } }
//   }
//
//   Will be interpreted as:
//
//   {
//       "foo": { "a": "a1", "b": "b1", "c": "c2" },
//       "bar": { "bazz": { "b": "b2", "c": "c2" } }
//   }
//
//   The referenced value does not need to appear in the tenant theme, so long
//   as it exists in the final synthesized theme. I.e., it's ok to reference
//   things that will be inserted by MUI or our other pre-processing.
//
// * Parts of the application inside <ThemeProvider.Subtheme themeName="foo" />
//   blocks will look for a top level "muiSubthemes" field with the specified
//   name, merge that over the existing "mui" top level theme, and then
//   re-derive all synthesized and inherited values.
//
// * Top-level members of `mui.palette` that have a `main` field but no
//   `contrastText` field will have a `contrastText` field synthesized for them.
//
// * Typographies `h2` through `h6` without a specified font size will have one
//   generated for them scaling naturally between `h1`'s and `body1`s.

// Tenant-specific themes are merged over this.
const genericPoetDefaults = {
    assets: {
        CompactLogoImage: '/compact-logo.png',
        FaviconImage: '/favicon.png',
        FullSizeLogoImage: '/full-logo.png'
    },
    features: {
        autoMint: false,
        disableSupply: false,
        googleAnalyticsConfig: 'G-9GPH5BYKXL',
        quantityLimit: 5
    },
    mui: {
        components: {
            MuiButton: {
                styleOverrides: {
                    outlined: {
                        backgroundColor: {
                            $inherit: 'mui.palette.background.paper'
                        },
                        '&:hover': {
                            backgroundColor: {
                                $inherit: 'mui.palette.background.paper'
                            },
                            color: { $inherit: 'mui.palette.primary.dark' },
                            borderColor: {
                                $inherit: 'mui.palette.primary.dark'
                            },
                            opacity: 0.9
                        },
                        '&:disabled': {
                            color: {
                                $inherit: 'mui.palette.primary.light',
                                $fn: (main, root) => lighten(main, 0.2)
                            }
                        }
                    },
                    root: {
                        borderRadius: 20,
                        fontSize: { $inherit: 'mui.typography.button.fontSize' }
                    },
                    text: {
                        '&:hover': {
                            color: { $inherit: 'mui.palette.primary.dark' },
                            textDecoration: 'underline',
                            backgroundColor: 'inherit'
                        }
                    }
                }
            },
            MuiButtonBase: {
                defaultProps: {
                    disableRipple: true
                }
            },
            MuiChip: {
                styleOverrides: {
                    root: {
                        borderRadius: 4,
                        fontSize: 14,
                        height: 24
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        backgroundColor: {
                            $inherit: 'mui.palette.inputBackground.main'
                        },
                        color: {
                            $inherit: 'mui.palette.inputBackground.contrastText'
                        },
                        fontSize: '1.28571rem'
                    }
                }
            },
            MuiLink: {
                defaultProps: {
                    underline: 'hover'
                }
            },
            MuiLoadingButton: {
                styleOverrides: {
                    fontSize: { $inherit: 'mui.typography.button.fontSize' },
                    loadingIndicator: {
                        color: {
                            $inherit: 'mui.palette.primary.light',
                            $fn: (main, root) => lighten(main, 0.2)
                        }
                    }
                }
            },
            MuiMenuItem: {
                styleOverrides: {
                    root: {
                        '&:hover': {
                            backgroundColor: {
                                $inherit:
                                    'mui.palette.selectItemHoverBackground.main'
                            },
                            color: {
                                $inherit:
                                    'mui.palette.selectItemHoverBackground.contrastText'
                            }
                        },
                        '&.Mui-selected': {
                            backgroundColor: {
                                $inherit:
                                    'mui.palette.selectItemSelectedBackground.main'
                            },
                            color: {
                                $inherit:
                                    'mui.palette.selectItemSelectedBackground.contrastText'
                            },
                            '&:hover': {
                                backgroundColor: {
                                    $inherit:
                                        'mui.palette.selectItemHoverSelectedBackground.main'
                                },
                                color: {
                                    $inherit:
                                        'mui.palette.selectItemHoverSelectedBackground.contrastText'
                                }
                            }
                        }
                    }
                }
            },
            MuiPaper: {
                styleOverrides: {
                    elevation0: {
                        backgroundColor: {
                            $inherit: 'mui.palette.background.mainApplication'
                        }
                    },
                    elevation3: {
                        backgroundColor: {
                            $inherit: 'mui.palette.background.paper'
                        }
                    }
                }
            },
            MuiTab: {
                defaultProps: {
                    disableRipple: true
                },
                styleOverrides: {
                    root: {
                        $inherit: 'mui.typography.body1',
                        borderBottom: '2px',
                        borderColor: { $inherit: 'mui.palette.divider' },
                        borderStyle: 'solid',
                        color: { $inherit: 'mui.palette.text.primary' },
                        fontSize: {
                            $inherit:
                                'mui.components.MuiInputBase.styleOverrides.root.fontSize'
                        },
                        lineHeight: 0,
                        transitionDuration: {
                            $inherit: 'mui.transitions.duration.short',
                            $suffix: 'ms'
                        },

                        '&.Mui-selected': {
                            color: { $inherit: 'mui.palette.text.primary' },
                            fontWeight: 700
                        }
                    }
                }
            },
            MuiTabs: {
                styleOverrides: {
                    indicator: {
                        height: '4px',
                        transition: 'none'
                    }
                }
            }
        },
        palette: {
            background: {
                mainApplication: { $inherit: 'mui.palette.background.paper' }
            },
            headerBackground: {
                main: 'rgba(0, 0, 0, 0)'
            },
            inputBackground: {
                main: { $inherit: 'mui.palette.background.paper' }
            },
            selectItemHoverBackground: {
                main: {
                    $inherit: 'mui.palette.background.paper',
                    $fn: (main, root) =>
                        Color(main).isLight()
                            ? {
                                  $inherit: 'mui.palette.background.paper',
                                  $fn: (main, root) =>
                                      Color(main).darken(0.2).hex()
                              }
                            : {
                                  $inherit: 'mui.palette.background.paper',
                                  $fn: (main, root) => lighten(main, 0.2)
                              }
                }
            },
            selectItemSelectedBackground: {
                main: { $inherit: 'mui.palette.primary.main' }
            },
            selectItemHoverSelectedBackground: {
                main: {
                    $inherit: 'mui.palette.background.paper',
                    $fn: (main, root) =>
                        Color(main).isLight()
                            ? {
                                  $inherit:
                                      'mui.palette.selectItemSelectedBackground.main',
                                  $fn: (main, root) =>
                                      Color(main).darken(0.2).hex()
                              }
                            : {
                                  $inherit:
                                      'mui.palette.selectItemSelectedBackground.main',
                                  $fn: (main, root) => lighten(main, 0.2)
                              }
                }
            },
            tagInputBrand: { $inherit: 'mui.palette.primary' },
            tagInputUser: { $inherit: 'mui.palette.secondary' },
            uploader: {
                contrastText: { $inherit: 'mui.palette.text.primary' },
                dark: { $inherit: 'mui.palette.grey.500' },
                light: { $inherit: 'mui.palette.grey.100' },
                main: { $inherit: 'mui.palette.grey.300' }
            }
        },
        typography: {
            button: {
                lineHeight: '22px',
                fontSize: '1.13rem',
                textTransform: 'none'
            },
            h1: {
                fontSize: '1.5rem'
            },
            headerLink: {
                fontSize: '1.125rem',
                fontWeight: '400'
            },
            learnMoreLink: { $inherit: 'mui.typography.body1' },
            poetDescription: { $inherit: 'mui.typography.body1' },
            poetTags: {
                $inherit: 'mui.typography.body2',
                fontSize: '.75rem',
                fontStyle: 'italic'
            },
            poetTitle: {
                $inherit: 'mui.typography.h3',
                fontSize: '1.1rem'
            },
            poetQuantity: { $inherit: 'mui.typography.h2' },
            subtitle1: {
                fontSize: '1.12rem'
            }
        }
    },
    muiSubthemes: {
        Header: {
            components: {
                MuiPaper: {
                    styleOverrides: {
                        elevation1: {
                            backgroundColor: {
                                $inherit: 'mui.palette.headerBackground.main'
                            },
                            backgroundImage: 'none',
                            boxShadow: 'none'
                        }
                    }
                }
            }
        },
        PoetTile: {
            components: {
                MuiCard: {
                    styleOverrides: {
                        root: {
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                            boxShadow:
                                '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)'
                        }
                    }
                }
            }
        },
        Uploader: {
            components: {
                MuiCard: {
                    styleOverrides: {
                        root: {
                            backgroundColor: {
                                $inherit: 'mui.palette.uploader.main'
                            },
                            color: {
                                $inherit: 'mui.palette.uploader.contrastText'
                            }
                        }
                    }
                }
            }
        }
    },
    variants: {
        viewWalletButton: 'outlined'
    }
};

function PoetThemeProvider({ children, theme: themeName = 'default' }) {
    const [poetTheme, setPoetTheme] = useState();

    useEffect(() => {
        (async () => {
            let rawTheme;

            if (
                themeName.startsWith('/') ||
                themeName.startsWith('http://') ||
                themeName.startsWith('https://')
            ) {
                ({ data: rawTheme } = await axios.get(
                    themeName.startsWith('/')
                        ? `${window.location.origin}${themeName}`
                        : themeName
                ));
            } else {
                rawTheme = await loadHardcodedTheme(themeName);
            }

            rawTheme = clone(rawTheme);

            if (rawTheme.mui?.$subthemes) {
                rawTheme.muiSubthemes = rawTheme.mui.$subthemes;
                delete rawTheme.mui.$subthemes;
            }

            setPoetTheme(buildPoetTheme(rawTheme));
        })();
    }, [themeName]);

    return !poetTheme ? null : (
        <PoetThemeContext.Provider value={poetTheme}>
            <MuiThemeProvider theme={clone(poetTheme.mui)}>
                {children}
            </MuiThemeProvider>
        </PoetThemeContext.Provider>
    );
}

const cachedSubthemes = new Map();

PoetThemeProvider.Subtheme = function Subtheme({
    children,
    className = '',
    themeName
}) {
    const poetTheme = useContext(PoetThemeContext);

    if (!cachedSubthemes.has(poetTheme)) {
        cachedSubthemes.set(poetTheme, {});
    }

    const subthemesPerBaseTheme = cachedSubthemes.get(poetTheme);
    if (!subthemesPerBaseTheme[themeName]) {
        const rawBaseTheme = poetTheme.rawTheme || {};
        const extensionTheme = {
            mui: poetTheme.muiSubthemes?.[themeName] || {}
        };
        const subtheme = buildPoetTheme(
            safeExtend(rawBaseTheme, extensionTheme)
        );

        subthemesPerBaseTheme[themeName] = subtheme;
    }

    return (
        <Box className={className}>
            <MuiThemeProvider theme={subthemesPerBaseTheme[themeName].mui}>
                {children}
            </MuiThemeProvider>
        </Box>
    );
};

async function loadHardcodedTheme(themeName) {
    const loadOrDefault = filename =>
        import(`../Themes/${themeName}/${filename}`)
            .catch(e => import(`../Themes/default/${filename}`))
            .then(m => m.default);

    const [assets, headLinks, features, mui, strings, classNames] =
        await Promise.all([
            loadOrDefault('assets.js'),
            loadOrDefault('head-links.js'),
            loadOrDefault('features.js'),
            loadOrDefault('mui.js'),
            loadOrDefault('strings.js'),
            loadOrDefault('classnames.js')
        ]);

    return { assets, headLinks, features, mui, strings, classNames };
}

function buildPoetTheme(rawTheme) {
    let processedTheme = safeExtend(
        {
            assets: {},
            classNames: {},
            features: {},
            headLinks: [],
            mui: {},
            strings: {
                brandingTags: []
            }
        },
        genericPoetDefaults,
        rawTheme
    );

    // This represents a copy of the theme as the theme specifier imagined it--
    // containing all imagined defaults, but without propogating forward any
    // special semantics or filling in any specified placeholders. We keep this
    // version around so we can A) perform a final merge-and-fill-in once we do
    // those things, thus taking advantage of any filled-in values, and B) to use
    // as a base for subthemes to extend.
    const themeWithDefaults = clone(processedTheme);

    // A couple of assets have special semantics.
    if (processedTheme.assets.FaviconImage) {
        processedTheme.headLinks.push({
            rel: 'icon',
            href: processedTheme.assets.FaviconImage
        });
    }

    if (processedTheme.assets.BackgroundImage) {
        const key = 'mui.components.MuiPaper.styleOverrides.elevation0';
        set(
            processedTheme,
            key,
            safeExtend(
                {
                    backgroundImage: `url("${processedTheme.assets.BackgroundImage}")`
                },
                get(processedTheme, key)
            )
        );
    }

    processedTheme = fillInRefs(
        processedTheme,
        safeExtend({ mui: createTheme() }, processedTheme)
    );

    // Let MUI do its filling in of the theme.
    processedTheme.mui = createTheme(processedTheme.mui);

    // Squirrel this away from subthemes to use.
    processedTheme.rawTheme = clone(themeWithDefaults);

    // Headings should scale in size from h1 -> body1
    const h1Size = fillInRefs(
        clone(processedTheme.mui.typography.h1.fontSize),
        clone(processedTheme),
        true
    );
    const body1Size = fillInRefs(
        clone(processedTheme.mui.typography.body1.fontSize),
        clone(processedTheme),
        true
    );

    const [startMagnitude, startUnit] = parseCssUnits(
        h1Size,
        processedTheme.mui.typography
    );
    const [endMagnitude, endUnit] = parseCssUnits(
        body1Size,
        processedTheme.mui.typography
    );

    if (startUnit !== endUnit) {
        throw new Error(
            `Units of h1 and body1 must be the same. ${startUnit} !== ${endUnit}`
        );
    }

    for (let headerType = 2; headerType <= 6; headerType++) {
        const interpolationAmt = (headerType - 1) / 5;

        processedTheme.mui.typography[
            `h${headerType}`
        ].fontSize = `${interpolate(
            startMagnitude,
            endMagnitude,
            interpolationAmt,
            0.45
        )}${startUnit}`;
    }

    for (const color of Object.values(processedTheme.mui.palette)) {
        if (color.main && !color.contrastText) {
            color.contrastText = processedTheme.mui.palette.getContrastText(
                color.main
            );
        }
    }

    // All our post processing is now complete. Let's fill in any remaining
    // `$inherit`s and throw if we can't find a referent.
    return safeExtend(
        processedTheme,
        fillInRefs(themeWithDefaults, processedTheme, true)
    );
}

function fillInRefs(o, src, throwOnMissing = false) {
    let result;

    if (typeof o === 'object') {
        if (o === null) {
            result = null;
        } else if (o.$inherit) {
            const referent = get(src, o.$inherit);

            if (referent === undefined) {
                if (throwOnMissing) {
                    throw new Error(
                        'Theme contains unavailable reference: ' + o.$inherit
                    );
                } else {
                    result = clone(o);
                    delete result.$inherit;
                }
            } else {
                if (typeof referent === 'object') {
                    result = fillInRefs(referent, src, throwOnMissing);

                    if (typeof result === 'object') {
                        for (const [key, value] of Object.entries(o)) {
                            result[key] = fillInRefs(
                                value,
                                src,
                                throwOnMissing
                            );
                        }
                        delete result.$inherit;
                    }
                } else {
                    result = clone(referent);
                }

                if (o.$fn) {
                    result = fillInRefs(
                        o.$fn(result, src),
                        src,
                        throwOnMissing
                    );
                }

                if (typeof result !== 'object' && o.$suffix) {
                    result = `${result}${o.$suffix}`;
                }
            }
        } else {
            result = Array.isArray(o) ? [] : {};
            for (const [key, value] of Object.entries(o)) {
                result[key] = fillInRefs(value, src, throwOnMissing);
            }
        }
    } else {
        result = clone(o);
    }

    return clone(result);
}

function interpolate(start, end, s, a) {
    return Math.pow(s, a) * (end - start) + start;
}

// Color('#000').lighten(.1) and .whiten() both appear to do nothing because
// 0 * 0.1 = 0???
function lighten(c, a) {
    return Color(c).mix(Color('#fff'), a).hex();
}

function parseCssUnits(c, typography) {
    let numericalPart = typeof c === 'number' ? c : parseFloat(c);
    let unitPart = typeof c === 'number' ? 'px' : c.match(/[^0-9\.]+/)[0];

    if (unitPart === 'px' && typography) {
        numericalPart = typography.pxToRem(numericalPart);
        unitPart = 'rem';
    }

    return [numericalPart, unitPart];
}

// `extend()` modifies its first argument in place, so its easy to end up with
// unintentional side-effects.
function safeExtend(...args) {
    return extend({}, ...args);
}

export default PoetThemeProvider;
