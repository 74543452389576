import './Wallet.css';
import {
    Alert,
    Box,
    Tab,
    Tabs,
    TextField,
    Autocomplete,
    Chip,
    Select,
    MenuItem,
    Typography as Font,
    InputAdornment
} from '@mui/material';
import { Search, ExpandMore } from '@mui/icons-material';
import bs58 from 'bs58';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useHistory } from 'react-router';
import RemWalletContext from '../../Contexts/rem-wallet-context.js';
import PoetThemeContext from '../../Contexts/poet-theme-context.js';
import ThemeProvider from '../ThemeProvider';

import getContracts from '../../Requests/get-contracts';
import getNfts from '../../Requests/get-nfts';
import Button from '../Button';
import Loader from '../Loader';
import PoetTile from '../PoetTile/PoetTile.js';
import useDisplayedAddress from '../../utils/use-displayed-address';

const sortOperations = {
    NAME_ASC: 'NAME_ASC',
    NAME_DESC: 'NAME_DESC',
    BLOCK_ASC: 'BLOCK_ASC',
    BLOCK_DESC: 'BLOCK_DESC'
};

export default function Wallet(props) {
    const remWalletCtx = useContext(RemWalletContext);
    const theme = useContext(PoetThemeContext);
    const params = useParams();
    const [poets, setPoets] = useState([]);
    const [pageInfo, setPageInfo] = useState({
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: '',
        endCursor: ''
    });

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    function parseTags() {
        return (searchParams.get('tags') ?? '')
            .split(',')
            .map(t => decodeURIComponent(t).trim())
            .filter(t => t);
    }

    const searchTags = useMemo(parseTags, [location.search]);

    function setSearchTags(newTags) {
        const newSearchParams = new URLSearchParams(location.search);
        newSearchParams.set(
            'tags',
            newTags.map(t => encodeURIComponent(t)).join(',')
        );

        changeDisplayedLocation({
            search: newSearchParams.toString()
        });
    }

    const [tagsError, setTagsError] = useState('');
    const [tagsInputValue, setTagsInputValue] = useState('');
    const [brandingTags, setBrandingTags] = useState(
        theme.strings.brandingTags || []
    );

    const [sort, setSort] = useState(sortOperations.BLOCK_DESC);

    const [getPoetsFailed, setGetPoetsFailed] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const history = useHistory();

    function changeDisplayedLocation(newLocation) {
        history.push({
            ...location,
            ...newLocation
        });
    }

    const displayedAddress = useDisplayedAddress(params.wallet);
    const userDisplayedAddress = remWalletCtx.displayName;

    const displayedTab = location.hash?.substring(1) || 'claimed';
    function setDisplayedTab(t) {
        changeDisplayedLocation({ hash: t });
    }

    if (displayedAddress.endsWith('.eth') && !params.wallet.endsWith('.eth')) {
        changeDisplayedLocation({ pathname: `/wallet/${displayedAddress}` });
    }

    const viewerIsOwner = remWalletCtx.isConnectedUser(params.wallet);

    async function handleTagClick(tag) {
        setSearchTags([tag]);
    }

    useEffect(() => {
        setGetPoetsFailed(false);
        setFirstLoad(true);

        if (viewerIsOwner && displayedTab === 'created') {
            getContractsData(
                params.wallet,
                '',
                [],
                searchTags,
                sort,
                brandingTags
            ).then(res => {
                if (res.error) {
                    setGetPoetsFailed(true);
                } else {
                    setPoets(res.contracts);
                    setPageInfo(res.pageInfo);
                }
                setFirstLoad(false);
            });
        } else {
            getNftsData(
                params.wallet,
                '',
                [],
                searchTags,
                sort,
                brandingTags
            ).then(res => {
                if (res.error) {
                    setGetPoetsFailed(true);
                } else {
                    setPoets(res.nfts);
                    setPageInfo(res.pageInfo);
                }
                setFirstLoad(false);
            });
        }
    }, [
        params.wallet,
        displayedTab,
        viewerIsOwner,
        remWalletCtx,
        setFirstLoad,
        setPoets,
        setPageInfo,
        searchTags,
        sort
    ]);

    return (
        <ThemeProvider.Subtheme className='Wallet' themeName='Wallet'>
            <div className='Wallet-header'>
                <Font variant='h1' component='div'>
                    {viewerIsOwner
                        ? 'Your POETs'
                        : `POETs owned by ${displayedAddress}`}
                </Font>
            </div>
            <div className='Wallet-body'>
                <div className='Wallet-search inline'>
                    <div className='Wallet-search-tags'>
                        <Autocomplete
                            value={searchTags}
                            onChange={(e, newValue) => {
                                setTagsError('');
                                if (newValue.length > 9) {
                                    setTagsError(
                                        'Cannot search on more than 9 tags, please delete some.'
                                    );
                                } else {
                                    setSearchTags(newValue);
                                }
                            }}
                            inputValue={tagsInputValue}
                            onInputChange={(e, newInputValue) => {
                                setTagsError('');
                                if (newInputValue.length > 30) {
                                    setTagsError(
                                        'Tags must be less than 30 characters long'
                                    );
                                } else {
                                    setTagsInputValue(newInputValue);
                                }
                            }}
                            freeSolo
                            multiple
                            options={[]}
                            renderTags={(tags, getTagProps) =>
                                tags.map((tag, index) => (
                                    <Chip
                                        variant='filled'
                                        color='tagInputUser'
                                        label={tag}
                                        {...getTagProps({ index })}
                                    />
                                ))
                            }
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    error={tagsError}
                                    helperText={tagsError}
                                    multiline={true}
                                    placeholder={
                                        searchTags.length === 0
                                            ? 'Search Tags'
                                            : ''
                                    }
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                <InputAdornment position='start'>
                                                    <Search color='primary' />
                                                </InputAdornment>
                                                {
                                                    params.InputProps
                                                        .startAdornment
                                                }
                                            </>
                                        )
                                    }}
                                />
                            )}
                        />
                    </div>
                    <div className='Wallet-search-sort'>
                        <Select
                            id='sort-by-select'
                            onChange={e => {
                                setSort(e.target.value);
                            }}
                            className='Wallet-search-sort-input'
                            defaultValue={sortOperations.BLOCK_DESC}
                            IconComponent={ExpandMore}
                        >
                            <MenuItem value={sortOperations.NAME_ASC}>
                                Name A-Z
                            </MenuItem>
                            <MenuItem value={sortOperations.NAME_DESC}>
                                Name Z-A
                            </MenuItem>
                            <MenuItem value={sortOperations.BLOCK_ASC}>
                                Oldest
                            </MenuItem>
                            <MenuItem value={sortOperations.BLOCK_DESC}>
                                Most Recent
                            </MenuItem>
                        </Select>
                    </div>
                </div>
                <div className='Wallet-content'>
                    {viewerIsOwner && (
                        <Tabs
                            variant='fullWidth'
                            value={displayedTab}
                            onChange={(e, v) => setDisplayedTab(v)}
                        >
                            <Tab value='claimed' label='Claimed' />
                            <Tab value='created' label='Created' />
                        </Tabs>
                    )}

                    <div
                        className='flex justify-center py-3'
                        id='wallet-scroll-parent'
                    >
                        <InfiniteScroll
                            dataLength={poets.length}
                            next={() => {
                                if (displayedTab === 'created') {
                                    getContractsData(
                                        params.wallet,
                                        pageInfo.endCursor,
                                        poets,
                                        searchTags,
                                        sort,
                                        brandingTags
                                    ).then(res => {
                                        if (res.error) {
                                            setGetPoetsFailed(true);
                                        } else {
                                            setPoets(res.contracts);
                                            setPageInfo(res.pageInfo);
                                        }
                                    });
                                } else {
                                    getNftsData(
                                        params.wallet,
                                        pageInfo.endCursor,
                                        poets,
                                        searchTags,
                                        sort,
                                        brandingTags
                                    ).then(res => {
                                        if (res.error) {
                                            setGetPoetsFailed(true);
                                        } else {
                                            setPoets(res.nfts);
                                            setPageInfo(res.pageInfo);
                                        }
                                    });
                                }
                            }}
                            scrollThreshold={1.0}
                            hasMore={pageInfo.hasNextPage}
                            loader={
                                !firstLoad && (
                                    <div className='flex block py-3 order-last justify-center w-full'>
                                        <Loader className='self-center' />
                                    </div>
                                )
                            }
                            endMessage={
                                <span className='flex block py-3 order-last justify-center w-full'>
                                    {firstLoad
                                        ? ''
                                        : poets.length === 0
                                        ? 'No poets found!'
                                        : 'End of poets'}
                                </span>
                            }
                            className='flex flex-wrap justify-center'
                            style={{
                                overflow: 'hidden'
                            }}
                        >
                            {firstLoad ? (
                                <div className='flex block py-3 order-last justify-center w-full'>
                                    <Loader className='self-center' />
                                </div>
                            ) : (
                                poets
                                    .filter(p => !p.nop)
                                    .map((poet, i) => (
                                        <div className='inline py-3 sm:px-2 md:py-6'>
                                            <PoetTile
                                                {...poet}
                                                handleTagClick={handleTagClick}
                                                key={i}
                                                isLink={true}
                                                isAlbum={poet.isAlbum}
                                                link={poet.link}
                                            />
                                        </div>
                                    ))
                            )}
                        </InfiniteScroll>
                    </div>
                    <div
                        className={`${
                            getPoetsFailed ? 'visible' : 'hidden'
                        } my-4`}
                    >
                        <Alert
                            onClose={() => setGetPoetsFailed(false)}
                            severity='error'
                        >
                            Something went wrong, please try again
                        </Alert>
                    </div>
                </div>
            </div>
        </ThemeProvider.Subtheme>
    );
}

function decodeAddress(address) {
    const encoded = address.split('_')[1];
    const decoded = bs58.decode(encoded);
    return Buffer.from(decoded).toString('utf8');
}

async function getNftsData(
    address,
    after = '',
    nfts = [],
    tags = [],
    sort = sortOperations.BLOCK_DESC,
    brandingTags
) {
    // This should make sure that all nfts we get back from the backend are POETs
    const predicates = [
        {
            operation: 'TEXT_SEARCH',
            fieldName: 'poetOwner',
            fieldValue: '0x'
        }
    ];

    for (const tag of tags) {
        predicates.push({
            operation: 'LIST_CONTAINS_STR_CI',
            fieldName: 'tags',
            fieldValue: tag
        });
    }

    if (brandingTags.length > 0) {
        predicates.push({
            operation: 'LIST_CONTAINS_STR_CI',
            fieldName: 'tags',
            fieldValue: brandingTags[0]
        });
    }

    const ownerAddress = address.startsWith('u_') ? null : address;
    const userId = address.startsWith('u_') ? decodeAddress(address) : null;

    const { res, error } = await getNfts({
        ownerAddress,
        userId,
        metadataPredicates: predicates,
        first: 9,
        after,
        sort
    });

    if (error && !res) {
        return { error };
    }

    const edges = res.edges || [];
    const resPageInfo = res.pageInfo || {
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: '',
        endCursor: ''
    };
    const nftsData = [...nfts];

    for (const { node } of edges) {
        try {
            if (node?.tokenId !== undefined && node?.contractAddress) {
                const parsedMetadata = JSON.parse(node.metadata);
                nftsData.push({
                    ...parsedMetadata,
                    tokenId: node.tokenId,
                    contractAddress: node.contractAddress
                });
            }
        } catch (e) {}
    }

    // hackey way to get around the fact that our page isn't updating if we
    // aren't adding anything to our list (which can happen if you get a return
    // of stuff without metadata)
    nftsData.push({ nop: true });

    return {
        nfts: nftsData,
        pageInfo: resPageInfo
    };
}

async function getContractsData(
    address,
    after = '',
    contracts = [],
    tags = [],
    sort = sortOperations.BLOCK_DESC,
    brandingTags
) {
    const predicates = [];

    const ownerAddress = address.startsWith('u_') ? null : address;
    const userId = address.startsWith('u_') ? decodeAddress(address) : null;

    if (ownerAddress) {
        predicates.push({
            operation: 'METADATA_ADDRESS',
            fieldName: 'poetOwner',
            fieldValue: ownerAddress
        });
    } else {
        predicates.push({
            operation: 'USER_ID',
            fieldName: 'poetOwner',
            fieldValue: userId
        });
    }

    for (const tag of tags) {
        predicates.push({
            operation: 'LIST_CONTAINS_STR_CI',
            fieldName: 'tags',
            fieldValue: tag
        });
    }

    if (brandingTags.length > 0) {
        predicates.push({
            operation: 'LIST_CONTAINS_STR_CI',
            fieldName: 'tags',
            fieldValue: brandingTags[0]
        });
    }

    const { res, error } = await getContracts({
        metadataPredicates: predicates,
        first: 9,
        after,
        sort
    });

    if (error && !res) {
        return { error };
    }

    const edges = res.edges || [];
    const resPageInfo = res.pageInfo || {
        hasNextPage: false,
        hasPreviousPage: false,
        startCursor: '',
        endCursor: ''
    };
    const contractsData = [...contracts];

    for (const { node } of edges) {
        const numTokens = node.numTokens || 0;
        let basicData = {
            address: node.address,
            isAlbum: numTokens > 1
        };
        basicData.link =
            numTokens === 1
                ? `/poet/${node.address}/token/1`
                : `/poet/${node.address}/share`;
        try {
            const parsedMetadata = JSON.parse(node.metadataJson);
            basicData = Object.assign(parsedMetadata, basicData);
        } catch (e) {}
        contractsData.push(basicData);
    }

    // hackey way to get around the fact that our page isn't updating if we
    // aren't adding anything to our list (which can happen if you get a return
    // of stuff without metadata)
    contractsData.push({ nop: true });

    return {
        contracts: contractsData,
        pageInfo: resPageInfo
    };
}
