import constants from '../utils/constants';
import RemWalletContext from '../Contexts/rem-wallet-context.js';

import { useContext } from 'react';

function CreateWalletLink({ extraClassNames, walletAddress }) {
    const remWalletCtx = useContext(RemWalletContext);

    return remWalletCtx.available ? null : (
        <a
            className={`text-subtle-gray underline ${extraClassNames}`}
            href={constants.CREATE_WALLET_HREF}
            rel='noreferrer'
            target='_blank'
        >
            Need a Wallet?
        </a>
    );
}

export default CreateWalletLink;
