import Link from '@mui/material/Button';
import useSize from '@react-hook/size';

import { useRef, useState } from 'react';

import './Expandable.css';

export default function Expandable({
    children,
    className = '',
    collapsedClass = 'Expandable--defaultCollapsed',
    moreClass = '',
    moreText = 'More',
    onMore
}) {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const contentRef = useRef();
    const viewRef = useRef();

    const [, contentHeight] = useSize(contentRef);
    const [, viewHeight] = useSize(viewRef);

    if (!onMore) {
        onMore = () => setIsCollapsed(!isCollapsed);
    }

    return (
        <div className={`Expandable ${className}`}>
            <div
                ref={viewRef}
                className={`Expandable-view ${toggleClass(
                    'is-truncated',
                    contentHeight > viewHeight
                )} ${toggleClass(collapsedClass, isCollapsed)}`}
            >
                <div ref={contentRef} className={`Expandable-content`}>
                    {children}
                </div>
            </div>
            <button
                className={`${moreClass} ${toggleClass(
                    'u-invisible',
                    contentHeight <= viewHeight
                )}`}
                onClick={onMore}
            >
                {moreText}
            </button>
        </div>
    );
}

function toggleClass(c, p) {
    return p ? c : '';
}
