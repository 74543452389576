import { useContext, useEffect, useMemo, useState } from 'react';

// Immediately triggers a refresh of `key` in `context` and then tracks `key`'s
// data state: ([value, loadingFlag, errorArray]).
export default function useRemoteValue(context, key) {
    const ctxValue = useContext(context);
    const serializedKey = ctxValue.serializeKey(key);

    // Trigger refresh the first time we start tracking a key
    const [fetchedKeys, setFetchedKeys] = useState({});

    // We'd really like this to execute in-line (i.e., use useMemo(), rather than
    // useEffect(), which defers), but that makes this hook pretty error prone,
    // since updating a parent component (the RemoteValueProvider via refresh() in
    // this case) within a child's render function generates an error.
    useEffect(() => {
        if (!fetchedKeys[serializedKey]) {
            ctxValue.refresh(key);
            setFetchedKeys({ ...fetchedKeys, [serializedKey]: true });
        }
    }, [ctxValue, fetchedKeys, key, serializedKey]);

    const [data, networkLoading, errors] = useMemo(
        () => ctxValue.get(key),
        [ctxValue, key]
    );

    // Since useEffect() above will be kicked off asynchronously, `networkLoading`
    // will initially be false (unless we already happen to be loading), then
    // immediately change to true as useEffect() kicks off the load. Let's present
    // a simpler view to our client--we know if we haven't started loading the
    // key, we're immediately -going- to start.
    const valueLoading = !fetchedKeys[serializedKey] || networkLoading;

    return useMemo(
        () => [data, valueLoading, errors],
        [data, valueLoading, errors]
    );
}
