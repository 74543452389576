import LensIcon from './LensIcon.jsx';
import PoetThemeContext from '../../Contexts/poet-theme-context.js';
import TwitterShareButton from '../../utils/PatchedTwitterShareButton';

import { FacebookShareButton } from 'react-share';
import {
    faFacebookSquare,
    faTwitterSquare
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext } from 'react';

import './ShareWidget.css';

export default function ShareWidget({
    hashtags = [],
    text,
    twitterExtraText = ''
}) {
    let twitterText = text;

    if (twitterExtraText) {
        twitterText += ` ${twitterExtraText}`;
    }

    if (hashtags.length > 0) {
        twitterText += ' ' + hashtags.map(h => `#${h}`).join(' ');
    }

    function shareToLens() {
        let shareUrl = `https://lenster.xyz/?text=${encodeURIComponent(text)}`;
        shareUrl =
            shareUrl + `&url=${encodeURIComponent(document.location.href)}`;
        shareUrl = shareUrl + `&via=${encodeURIComponent('poe.xyz')}`;
        shareUrl =
            shareUrl +
            `&hashtags=${hashtags.map(h => encodeURIComponent(h)).join(',')}`;
        window.open(shareUrl, '_newtab');
    }

    return (
        <div className='ShareWidget'>
            <TwitterShareButton
                url={document.location.href}
                title={twitterText}
            >
                <FontAwesomeIcon icon={faTwitterSquare} />
            </TwitterShareButton>
            <FacebookShareButton
                url={document.location.href}
                quote={text}
                hashtag={hashtags.length > 0 ? `#${hashtags[0]}` : undefined}
            >
                <FontAwesomeIcon icon={faFacebookSquare} />
            </FacebookShareButton>
            <LensShareButton onClick={shareToLens} />
        </div>
    );
}

function LensShareButton({ onClick }) {
    const theme = useContext(PoetThemeContext);

    return (
        <button className='ShareWidget-lensShareButton' onClick={onClick}>
            <LensIcon
                className='ShareWidget-lensShareIcon'
                foregroundColor={theme.mui.palette.text.primary}
                backgroundColor={theme.mui.palette.primary.contrastText}
            />
        </button>
    );
}
