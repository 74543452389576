import graphqlRequest from './graphql-request';

export default async function getContract(variables) {
    const { res, error } = await graphqlRequest(
        `
        query GetContract(
            $address: String!
        ) {
            contractV2(address: $address) {
                success
                contract {
                    ... on Contract721 {
                      address
                      metadataJson
                      metadataUri
                      name
                      symbol
                      totalSupply
                      totalClaimed
                    }

                    ... on Contract1155 {
                      address
                      metadataJson
                      metadataUri
                      numTokens
                    }
                }
            }
        }
    `,
        variables
    );

    return {
        res: res?.contractV2 || { success: false },
        error
    };
}
