import graphqlRequest from './graphql-request';
import lodash from 'lodash';

export default async function useCreateImageUploadUrls() {
    const { res, error } = await graphqlRequest(
        `
      mutation CreateImageUploadUrls {
          createImageUploadUrls {
              getImageUrl
              postImageUrl
              success
          }
      }
    `
    );

    return {
        res: lodash.get(res, 'createImageUploadUrls', {}),
        error
    };
}
