import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import Chip from '@mui/material/Chip';
import createImageUploadUrls from '../../Requests/create-image-upload-urls';
import CreateWalletLink from '../CreateWalletLink';
import deployContract from '../../Requests/deploy-contract';
import Font from '@mui/material/Typography';
import issueNft from '../../Requests/issue-nft';
import Link from '@mui/material/Link';
import LoginSomehow from '../LoginSomehow.js';
import PoetThemeContext from '../../Contexts/poet-theme-context.js';
import RemWalletContext from '../../Contexts/rem-wallet-context.js';
import TextField from '@mui/material/TextField';

import { ImageUpload } from './ImageUpload';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { HighlightOff } from '@mui/icons-material';
import { useContext, useEffect, useState, createRef } from 'react';
import { ContractDataContext } from '../ContractDataProvider';
import { PoetDataContext } from '../PoetDataProvider';
import { ReCAPTCHAContext } from '../App.js';
import { When } from 'react-if';

import './New.css';

export default function New() {
    const contractDataCtx = useContext(ContractDataContext);
    const poetDataCtx = useContext(PoetDataContext);
    const remWalletCtx = useContext(RemWalletContext);
    const theme = useContext(PoetThemeContext);
    const recaptcha = useContext(ReCAPTCHAContext);

    const quantityLimit =
        remWalletCtx.userSupplyLimit ?? theme.features.quantityLimit ?? 5000;

    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [imageLoading, setImageLoading] = useState(false);

    const [tags, setTags] = useState([]);
    const [tagsInputValue, setTagsInputValue] = useState('');
    const [brandingTags, setBrandingTags] = useState([]);

    const [totalSupply, setTotalSupply] = useState(1);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [hasSubmittedOnce, setHasSubmittedOnce] = useState(false);
    const [deployContractFailed, setDeployContractFailed] = useState(false);
    const [formInvalid, setFormInvalid] = useState(false);
    const [imageErrorMessage, setImageErrorMessage] = useState('');
    const [quantityErrorMessage, setQuantityErrorMessage] = useState('');
    const [tagsError, setTagsError] = useState(false);
    const history = useHistory();

    // Even though the RemWalletContext (and thus our restore state) persists
    // after we are gone, we'd like to clear any of our data.
    useEffect(() => () => remWalletCtx.clearState(), []);

    useEffect(() => {
        setName(remWalletCtx.formState.name || name);
        setDescription(remWalletCtx.formState.description || description);
        setTotalSupply(remWalletCtx.formState.totalSupply || totalSupply);
        setImages(remWalletCtx.formState.images || images);
        setTags(remWalletCtx.formState.tags || tags);
        setBrandingTags(theme.strings.brandingTags || brandingTags);
    }, [remWalletCtx.formState]);

    const isFormValid = () => {
        const tokenLimit = theme.features.tokenLimit || 10;
        if (!totalSupply) {
            setQuantityErrorMessage('This field is required');
        } else if (totalSupply > quantityLimit) {
            setQuantityErrorMessage(
                `Quantity cannot be greater than ${quantityLimit}`
            );
        } else {
            setQuantityErrorMessage('');
        }

        if (images.length === 0 && hasSubmittedOnce) {
            setImageErrorMessage('This field is required');
        } else if (images.length > tokenLimit) {
            setImageErrorMessage(
                `You cannot use more than ${tokenLimit} images`
            );
        }

        if (
            !name ||
            !totalSupply ||
            totalSupply > quantityLimit ||
            (images.length === 0 && hasSubmittedOnce) ||
            images.length > tokenLimit
        ) {
            return false;
        }

        return true;
    };

    useEffect(() => {
        isFormValid();
    });

    const handleFileInput = async e => {
        const tokenLimit = theme.features.tokenLimit || 10;
        const files = e.target.files;
        if (files.length > tokenLimit) {
            setImageErrorMessage(
                `You cannot use more than ${tokenLimit} images`
            );
            return;
        }
        for (const file of files) {
            if (file.size > 10485760) {
                setImageErrorMessage(
                    'Max image size is 10mb. Please choose a smaller image'
                );
                return;
            }
        }

        setImageErrorMessage('');
        setImageLoading(true);
        let getImageUrls = [];
        let postImageUrls = [];
        let allSuccessful = true;
        let errors = [];
        for (let i = 0; i < files.length; i++) {
            const {
                res: { getImageUrl, postImageUrl, success },
                error
            } = await createImageUploadUrls();
            allSuccessful = allSuccessful && success;
            getImageUrls.push(getImageUrl);
            postImageUrls.push(postImageUrl);
            if (error) {
                errors.push(error);
            }
        }
        if (!allSuccessful || errors.length !== 0) {
            setImages([]);
            setImageLoading(false);
            setImageErrorMessage(
                `Something went wrong uploading your image: ${errors[0]} Please try again`
            );
            return;
        }

        for (let j = 0; j < files.length; j++) {
            const formData = new FormData();
            formData.append('image', files[j]);
            await axios.post(postImageUrls[j], formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
        }

        setImages(getImageUrls);
        remWalletCtx.mergeFormState({ images: getImageUrls });
        setImageLoading(false);
    };

    const submit = async e => {
        e.preventDefault();
        let captchaToken = await recaptcha.executeAsync();

        setHasSubmitted(true);
        setHasSubmittedOnce(true);
        if (!isFormValid()) {
            setFormInvalid(true);
            setHasSubmitted(false);
            return;
        }

        const allTags = new Set(tags.concat(brandingTags));

        const metadata = JSON.stringify({
            name,
            description,
            image: images[0],
            tags: Array.from(allTags)
        });

        setDeployContractFailed(false);

        const { res: deployRes, error: deployError } = await deployContract(
            {
                captchaToken,
                metadata,
                perTokenSupply: totalSupply,
                tokenImageUris: images,
                userId: remWalletCtx.auth0Login?.sub,
                userWallet: remWalletCtx.web3Wallet
            },
            {
                headers: remWalletCtx.headers
            }
        );

        if (deployRes.success) {
            const { contractAddress, metadataUri } = deployRes.contract;
            const ownerAddress = remWalletCtx.urlWallet;

            contractDataCtx.update(contractAddress, {
                name,
                symbol: 'POET',
                metadata: JSON.stringify({
                    ...JSON.parse(metadata),

                    poetOwner:
                        remWalletCtx.web3Wallet || remWalletCtx.auth0Login?.sub
                }),

                totalSupply,
                userId: remWalletCtx.auth0Login?.sub,
                userWallet: remWalletCtx.web3Wallet
            });

            if (theme.features.autoMint) {
                captchaToken = await recaptcha.executeAsync();
                const { res: mintRes, error: mintError } = await issueNft(
                    {
                        captchaToken,
                        contractAddress,
                        toAddress: remWalletCtx.web3Wallet,
                        key: ownerAddress,
                        metadataUri,
                        userId: remWalletCtx.auth0Login?.sub
                    },
                    {
                        headers: remWalletCtx.headers
                    }
                );

                if (mintRes.success) {
                    const data = {
                        contractAddress,
                        metadataUri,
                        metadata,
                        ownerAddress,
                        tokenId: mintRes.tokenId
                    };

                    poetDataCtx.update(
                        {
                            contract: contractAddress,
                            tokenId: mintRes.tokenId
                        },
                        data
                    );
                } else {
                    setDeployContractFailed(true);
                    setHasSubmitted(false);
                    return;
                }

                history.push(
                    `/poet/${contractAddress}/token/${mintRes.tokenId}?claim=successful`
                );
            } else {
                if (images.length === 1) {
                    history.push(`/poet/${contractAddress}/token/1`);
                } else {
                    history.push(`/poet/${contractAddress}/share`);
                }
            }
        } else {
            setDeployContractFailed(true);
            setHasSubmitted(false);
        }
    };

    return (
        <form className='flex flex-col max-w-xl mx-auto'>
            <CreateWalletLink extraClassNames='inline md:hidden' />
            <Font variant='h1' className='py-2 mt-3'>
                {theme.strings.title || 'Create a POET'}
            </Font>
            <Font variant='subtitle1'>
                {theme.strings.subtitle ||
                    'A POET is a Proof of Experience Token from your everyday life.'}
            </Font>
            <Font variant='learnMoreLink'>
                <Link href={'/learn'}>Learn more</Link>
            </Font>
            <div className='py-3'>
                <Font variant='h2'>
                    {theme.strings.newPoetTitleFieldLabel || 'POET Title'} *
                </Font>
                <TextField
                    fullWidth
                    value={name}
                    onChange={e => {
                        setName(e.target.value);
                        remWalletCtx.mergeFormState({ name: e.target.value });
                    }}
                    required
                    error={formInvalid && !name}
                    helperText={
                        formInvalid && !name && 'This field is required'
                    }
                />
            </div>
            <div className='py-3'>
                <Font variant='h2'>Upload Media *</Font>
                <ImageUpload
                    errorMessage={imageErrorMessage}
                    images={images}
                    loading={imageLoading}
                    setImages={setImages}
                    handleFileInput={handleFileInput}
                />
            </div>
            <div className='py-3'>
                <Font variant='h2'>Description</Font>
                <TextField
                    fullWidth
                    value={description}
                    onChange={e => {
                        setDescription(e.target.value);
                        remWalletCtx.mergeFormState({
                            description: e.target.value
                        });
                    }}
                    multiline={true}
                    maxRows={10}
                    minRows={3}
                />
            </div>
            {!theme.features.disableSupply && (
                <div className='pt-3 pb-5'>
                    <Font variant='h2'>{`Quantity (up to ${quantityLimit}) *`}</Font>
                    <div className='New-quantityInputRow'>
                        <TextField
                            sx={{ width: '128px' }}
                            value={totalSupply}
                            onChange={e => {
                                const newTotalSupply =
                                    parseInt(e.target.value) || '';
                                setTotalSupply(newTotalSupply);
                                remWalletCtx.mergeFormState({
                                    totalSupply: newTotalSupply
                                });
                            }}
                            required
                            error={formInvalid}
                            helperText={
                                !totalSupply
                                    ? 'This field is required'
                                    : quantityErrorMessage
                            }
                        />
                        <When
                            condition={
                                remWalletCtx.userSupplyLimit === undefined
                            }
                        >
                            <div className='New-quantityInputCta'>
                                <Font>
                                    {`Need more than ${quantityLimit}?`}
                                </Font>
                                <p> </p>
                                <Link
                                    className='New-quantityInputCtaLink'
                                    href={
                                        theme?.quantityCta?.link?.href ??
                                        'mailto:hey@bandwagonfanclub.com?subject=POET%20limit%20increase'
                                    }
                                >
                                    {theme?.quantityCta?.link?.text ??
                                        'Contact us!'}
                                </Link>
                            </div>
                        </When>
                    </div>
                </div>
            )}
            <div className='py-3'>
                <Font variant='h2'>Tags</Font>
                <Autocomplete
                    value={[...brandingTags, ...tags]}
                    onChange={(e, newValue) => {
                        setTagsError('');
                        const filtered = newValue.filter(
                            tag => !brandingTags.includes(tag)
                        );
                        if (filtered.length > 10) {
                            setTagsError(
                                'A POET cannot have more than 10 tags, please delete some and try again'
                            );
                        } else {
                            setTags(filtered);
                            remWalletCtx.mergeFormState({ tags: filtered });
                        }
                    }}
                    inputValue={tagsInputValue}
                    onInputChange={(e, newInputValue) => {
                        setTagsError('');
                        if (newInputValue.length > 30) {
                            setTagsError(
                                'Tags must be less than 30 characters long'
                            );
                        } else {
                            setTagsInputValue(newInputValue);
                        }
                    }}
                    freeSolo
                    multiple
                    options={[]}
                    renderTags={(tags, getTagProps) => (
                        <>
                            {brandingTags.map(tag => (
                                <Chip
                                    key={`tag-${tag}`}
                                    variant='filled'
                                    color='tagInputBrand'
                                    label={tag}
                                    clickable={false}
                                    disabled={true}
                                />
                            ))}
                            {tags
                                .filter(
                                    (tag, index) => !brandingTags.includes(tag)
                                )
                                .map((tag, index) => (
                                    <Chip
                                        key={`tag-${tag}`}
                                        variant='filled'
                                        color='tagInputUser'
                                        label={tag}
                                        deleteIcon={<HighlightOff />}
                                        {...getTagProps({
                                            index: tags.indexOf(tag)
                                        })}
                                    />
                                ))}
                        </>
                    )}
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='large'
                            error={tagsError}
                            helperText={tagsError}
                        />
                    )}
                />
            </div>
            <div>
                {remWalletCtx.available || hasSubmitted ? (
                    <div className='New-createButtonContainer'>
                        <div className='New-createButtonSizer'>
                            <LoadingButton
                                onClick={e => {
                                    submit(e).catch(console.error);
                                }}
                                loading={hasSubmitted}
                                loadingIndicator='Creating...'
                                fullWidth
                                variant='contained'
                                color='primary'
                                size='large'
                            >
                                {hasSubmitted ? 'Creating...' : 'Create'}
                            </LoadingButton>
                        </div>
                    </div>
                ) : (
                    <LoginSomehow />
                )}
            </div>
            <div
                className={`${
                    deployContractFailed ? 'visible' : 'hidden'
                } my-4`}
            >
                <Alert
                    onClose={() => setDeployContractFailed(false)}
                    severity='error'
                >
                    Something went wrong, please try again
                </Alert>
            </div>
        </form>
    );
}
