import RemWalletContext from '../Contexts/rem-wallet-context.js';
import PoetThemeContext from '../Contexts/poet-theme-context.js';
import { Button } from '@mui/material';

import { Link } from 'react-router-dom';
import { useContext } from 'react';

function ViewWalletPageButton(props) {
    const remWalletCtx = useContext(RemWalletContext);
    const theme = useContext(PoetThemeContext);

    const currentPath = window.location.pathname;
    const toUserPath = `/wallet/${remWalletCtx.urlWallet}`;

    return (
        <Link to={toUserPath}>
            <Button
                fullWidth
                variant={theme.variants.viewWalletButton || 'outlined'}
            >
                {currentPath === toUserPath
                    ? remWalletCtx.displayName
                    : theme.strings.viewWalletButtonText || 'View your POETs'}
            </Button>
        </Link>
    );
}

export default ViewWalletPageButton;
