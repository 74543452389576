import { Link } from '@mui/material';

export default function PrivacyPolicy() {
    return (
        <div className='flex xl:flex-row xl:max-h-full flex-col max-w-6xl'>
            <div
                id='navigate'
                className='px-4 xl:overflow-y-auto xl:order-last'
            >
                <p className='text-2xl font-bold'>Table of Contents</p>
                <div className='px-2 pb-2'>
                    <ul>
                        <li>
                            <Link href='#top'>Privacy Policy</Link>
                        </li>
                        <li>
                            <Link href='#information-collection'>
                                Information We Collect
                            </Link>
                        </li>
                        <li>
                            <Link href='#information-use'>
                                How We Use Information We Collect
                            </Link>
                        </li>
                        <li>
                            <Link href='#information-security'>
                                How We Secure Information
                            </Link>
                        </li>
                        <li>
                            <Link href='#information-sharing'>
                                How We Share Personal Information
                            </Link>
                        </li>
                        <li>
                            <Link href='#choices'>What Choices You Have</Link>
                        </li>
                        <li>
                            <Link href='#updates'>
                                Updates to Our Privacy Notice
                            </Link>
                        </li>
                        <li>
                            <Link href='#contact'>Contact Information</Link>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='flex-col p-4 max-w-3xl xl:max-h-screen xl:overflow-y-auto'>
                <p id='top' className='py-4 text-3xl font-bold'>
                    Privacy Policy
                </p>
                <p className='font-bold'>
                    Last Updated / Effective Date: September 20, 2022
                </p>
                <p className='py-2'>
                    This Privacy Notice describes how POET collects, uses, and
                    shares your personal information in relation to POET
                    services and websites on which this notice appears
                    (collectively "Services"). This Privacy Notice also
                    describes how POET manages data and the choices POET
                    visitors and customers have regarding how data is managed.
                </p>
                <p className='py-2'>
                    We collect information you choose to give us and other
                    information that may be automatically collected from your
                    use of our Services, including from your device. We use that
                    information to operate our business, and for a variety of
                    other purposes set forth below.
                </p>
                <p className='pt-2 pb-4'>
                    This Privacy Notice does not apply to third parties or their
                    services. This Privacy Notice does not apply to information
                    that cannot be identified to any individual, household, or
                    their devices.
                </p>
                <p
                    id='information-collection'
                    className='py-4 text-2xl font-bold'
                >
                    Information We Collect
                </p>
                <p className='py-2'>
                    POET offers consumers the ability to upload content and
                    create a non-fungible token consisting of a link to that
                    content from a public blockchain. Content shared on or
                    linked from the blockchain is (and may permanently remain)
                    publicly available. Any information you choose to provide in
                    such uploaded content may therefore be shared.
                </p>
                <p className='py-2'>
                    To provide POET Services and to support our website, we
                    collect data by which you may be personally identified, such
                    as a first and last name, an email address, a telephone
                    number, and identifiers that may permit us to contact you,
                    and as described below.
                </p>
                <p className='py-2'>
                    We may collect this information from a variety of sources,
                    including:
                </p>
                <ul className='list-disc pt-2 pb-4 px-4'>
                    <li>Directly from you when you provide it to us.</li>
                    <li>
                        From third parties, including contacts in your network.
                    </li>
                    <li>Automatically as you navigate through the Sites.</li>
                </ul>
                <p className='py-2'>
                    <u>
                        Information directly from you when you provide it to us.
                    </u>{' '}
                    The information we collect on or through our Services may
                    include information that you provide directly to us,
                    including wallet addresses. This includes information
                    provided at the time you upload content, sign up for
                    communications, or communicate with us.
                </p>
                <p className='py-2'>
                    <u>Information about transactions made on our Services.</u>{' '}
                    If you use our Service, we collect information about your
                    use and interaction with our service. This includes NFTs
                    created, metadata, tags, titles, descriptions, public wallet
                    information or other information associated with such NFTs
                    and contact details. (By “public wallet,” we are referring
                    to digital identifiers typically consisting of a public key
                    and associated with a private key controlled by an
                    individual.)
                </p>
                <p className='pt-2 pb-4'>
                    <u>Information we collect from third party sources.</u>{' '}
                    Advertisers, analytic services, and other third parties
                    provide us information in connection with tools we offer and
                    through tools they use to collect information about you when
                    you use our Services. The information they collect may be
                    associated with your personal information or they may
                    collect information about your online activities over time
                    and across devices. They and we may use this information to
                    provide you with interest-based advertising or other
                    targeted content, to understand our Services, audiences, and
                    to otherwise analyze traffic on our Services.
                </p>
                <p id='information-use' className='py-4 text-2xl font-bold'>
                    How We Use Information We Collect
                </p>
                <p className='py-2'>
                    We may use data we collect for a variety of purposes,
                    including the following:
                </p>
                <ul className='list-disc pt-2 pb-4 px-4'>
                    <li>
                        To operate our business, which includes providing a
                        forum for consumers to mint NFTs.
                    </li>
                    <li>
                        To provide, maintain, monitor, and improve our Services.
                    </li>
                    <li>To provide customer support.</li>
                    <li>
                        To communicate with you, including about products and/or
                        services you may be interested in.
                    </li>
                    <li>
                        To monitor and enforce our legal terms or similar terms.
                    </li>
                    <li>
                        To comply with law and satisfy our regulatory compliance
                        obligations.
                    </li>
                    <li>
                        To detect and prevent fraud and other prohibited,
                        illicit or illegal activity.
                    </li>
                    <li>
                        For other purposes permitted by law or to which you
                        consent.
                    </li>
                </ul>
                <p className='pt-2 pb-4'>
                    Please note that we may combine the information we gather
                    about you in identifiable form, including information from
                    third parties. We may use this information, for example, to
                    improve and personalize our services, content and
                    advertising.
                </p>
                <p
                    id='information-security'
                    className='py-4 text-2xl font-bold'
                >
                    How We Secure Information
                </p>
                <p className='pt-2 pb-4'>
                    We are committed to maintaining measures to protect the
                    security of your information. Of course, despite these
                    measures, no network or system is ever entirely secure and
                    we cannot guarantee the security of networks and systems
                    that we operate or that are operated on our behalf.
                </p>
                <p id='information-sharing' className='py-4 text-2xl font-bold'>
                    How We Share Personal Information
                </p>
                <p className='py-2'>
                    We may share your information with third parties as
                    permitted or required by law, or as directed or authorized
                    by you. For example, we may disclose your information:
                </p>
                <ul className='list-disc pt-2 pb-4 px-4'>
                    <li>
                        With other service providers who support our business
                        (such as information technology or hosting providers).
                    </li>
                    <li>
                        With our professional advisors who provide legal,
                        compliance, accounting, banking, or consulting services.
                    </li>
                    <li>
                        To comply with our legal obligations or to protect the
                        interests, property or legal rights of you, ourselves,
                        or third parties.
                    </li>
                    <li>
                        With law enforcement, officials, or other third parties
                        when we are compelled to do so by a subpoena, court
                        order, or similar law enforcement request, or when we
                        believe in good faith that the disclosure of personal
                        information is necessary to prevent physical harm or
                        financial loss, to report suspected illegal activity, or
                        to investigate violations of this Privacy Notice or
                        other applicable terms.
                    </li>
                    <li>
                        With companies or other entities in connection with, or
                        during the negotiation of, any merger, sale of company
                        stock or assets, financing, acquisition, divestiture or
                        dissolution of all or a portion of our business.
                    </li>
                    <li>
                        For other legal purposes, such as to enforce our legal
                        terms, or to exercise or defend legal claims.
                    </li>
                    <li>With your direction or consent.</li>
                </ul>
                <p className='pt-2 pb-4'>
                    In addition, when you mint content through POET, information
                    about your purchase will be made publicly available on a
                    public blockchain. Currently, POET uses the Palm blockchain.
                    If you want to understand Palm’s information practices, for
                    which we are not responsible, you should refer to their
                    privacy notice. This publicly available information may
                    include POET’s address; product metadata, including data
                    about the content such as tags, titles, and associated
                    descriptions; contract / smart contract information related
                    to the transaction; and wallet address. This and other
                    information may be seen by other users of the blockchain
                    used by POET. Such information, and content you upload to
                    our service, may be publicly available indefinitely.
                </p>
                <p id='choices' className='py-4 text-2xl font-bold'>
                    What Choices You Have
                </p>
                <p className='py-2'>
                    <u>Update personal information.</u> If you maintain an
                    online account with us, you can log in to that account to
                    review and change certain information you have provided to
                    us. Hosting content may be retained indefinitely and
                    publicly available.
                </p>
                <p className='py-2'>
                    <u>Marketing communications.</u> You may receive marketing
                    information from POET. You may opt out of receiving
                    marketing emails, by following the unsubscribe link in each
                    email, or by contacting us at hey@bandwagonfanclub.com. You
                    may continue to receive non-marketing emails from us after
                    you opt-out.
                </p>
                <p className='pt-2 pb-4'>
                    <u>Cookies.</u> If we collect or permit cookies on our site,
                    you should know that you have a number of choices regarding
                    certain cookies. Most web browsers automatically accept
                    cookies, but you may modify your browser’s setting to notify
                    you of cookie placement or decline cookies. Your browser
                    help documentation should explain these options with respect
                    to your particular browser.
                </p>
                <p id='updates' className='py-4 text-2xl font-bold'>
                    Updates to Our Privacy Notice
                </p>
                <p className='pt-2 pb-4'>
                    We may update this Privacy Notice from time to time in order
                    to provide clarification or notice of changes to our
                    practices. If we make changes, we will revise the Effective
                    Date at the top of this Privacy Notice. Changes to this
                    Privacy Notice will be effective once they are posted unless
                    otherwise indicated.
                </p>
                <p id='contact' className='py-4 text-2xl font-bold'>
                    Contact Information
                </p>
                <p className='pt-2 pb-4'>
                    POET is a branded application created by BandwagonFanclub.
                    BandwagonFanclub is located in Greenville, South Carolina.
                    If you have any questions or concerns about this privacy
                    notice or the privacy practices at POET, please contact us
                    at hey@bandwagonfanclub.com or by visiting us at{' '}
                    <Link href='https://bandwagonfanclub.com/contact-us/'>
                        https://bandwagonfanclub.com/contact-us/
                    </Link>
                    .
                </p>
            </div>
        </div>
    );
}
