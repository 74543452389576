import Loader from '../Loader';
import Image from './Image';
import Carousel from '../Carousel/Carousel';
import Uploader from './Uploader';

import { FormControl } from '@mui/material';

import '../Carousel/Carousel.css';

export function ImageUpload(props) {
    const { loading, images = [] } = props;

    return (
        <FormControl fullWidth={true}>
            {loading ? (
                <Loader />
            ) : images.length > 1 ? (
                <Carousel
                    allowDelete={true}
                    onDelete={async image => {
                        const filteredImages = images.filter(
                            img => img !== image
                        );
                        props.setImages(filteredImages);
                    }}
                >
                    {images.map((i, index) => (
                        <img
                            src={i}
                            alt={`Uploaded image ${index}`}
                            className='Carousel-slideContent'
                        />
                    ))}
                </Carousel>
            ) : images.length === 1 ? (
                <Image image={images[0]} {...props} />
            ) : (
                <Uploader {...props} />
            )}
        </FormControl>
    );
}
