import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './Components/App';
import reportWebVitals from './reportWebVitals';
import ContractDataProvider from './Components/ContractDataProvider.js';
import PoetDataProvider from './Components/PoetDataProvider.js';
import ThemeProvider from './Components/ThemeProvider.js';

const container = document.getElementById('root');
const root = ReactDOM.createRoot(container);
root.render(
    <React.StrictMode>
        <ContractDataProvider>
            <PoetDataProvider>
                <ThemeProvider theme={window.__RUNTIME_CONFIG__.THEME}>
                    <App />
                </ThemeProvider>
            </PoetDataProvider>
        </ContractDataProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
