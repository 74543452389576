import graphqlRequest from './graphql-request';

export default async function issueNft(variables, options) {
    const graphqlResponse = await graphqlRequest(
        `
      mutation IssueNft(
          $contractAddress: String!
          $toAddress: String
          $userId: String
          $key: String!
          $metadataUri: String!
          $captchaToken: String!
      ) {
          issueNft(
              contractAddress: $contractAddress
              toAddress: $toAddress
              userId: $userId
              key: $key
              metadataUri: $metadataUri
              captchaToken: $captchaToken
          ) {
              success
              tokenId
              txHash
          }
      }
    `,
        variables,
        options
    );

    if (graphqlResponse?.res?.issueNft?.success) {
        return {
            res: graphqlResponse?.res?.issueNft,
            error: graphqlResponse.error
        };
    }

    return {
        res: {
            success: false,
            txHash: null
        },
        error: graphqlResponse.error
    };
}
