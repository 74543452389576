import ClaimPoet from '../../Utils/ClaimPoet.js';
import Expandable from '../../Utils/Expandable.jsx';
import ReactPlaceholder from 'react-placeholder';
import useDisplayAddress from '../../../utils/use-displayed-address.js';

import { IoChevronForwardCircle } from 'react-icons/io5';
import { Link, useHistory } from 'react-router-dom';
import { Else, If, Then } from 'react-if';
import { createContext, useContext } from 'react';
import { PoetDataContext } from '../../PoetDataProvider';

import 'react-placeholder/lib/reactPlaceholder.css';
import './FeaturedPoetInfo.css';

const FeaturedPoetReadyContext = createContext();

export default function FeaturedPoetInfo({ className = '', contract, token }) {
    return (
        <FeaturedPoetReadyContext.Provider value={!!contract}>
            <Info className={className} contract={contract} token={token} />
        </FeaturedPoetReadyContext.Provider>
    );
}

function Text({ children, className }) {
    const ready = useContext(FeaturedPoetReadyContext);
    return (
        <If condition={ready}>
            <Then>
                <div className={`${className} u-placeholderDiv`}>
                    {children}
                </div>
            </Then>
            <Else>
                <ReactPlaceholder
                    showLoadingAnimation={true}
                    type='text'
                    className={className}
                    ready={false}
                />
            </Else>
        </If>
    );
}

function TextRow({ children, className }) {
    const ready = useContext(FeaturedPoetReadyContext);
    return (
        <If condition={ready}>
            <Then>
                <div className={className}>{children}</div>
            </Then>
            <Else>
                <ReactPlaceholder
                    showLoadingAnimation={true}
                    type='textRow'
                    className={className}
                    ready={false}
                />
            </Else>
        </If>
    );
}

function Info({ className, contract, token }) {
    const history = useHistory();
    const owner = useDisplayAddress(token?.metadata?.properties?.poetOwner);

    const totalClaimed = token?.totalSupply - token?.claimableSupply;
    const poetDataCtx = useContext(PoetDataContext);

    const alreadyClaimedText =
        totalClaimed === 0
            ? 'No one owns this yet!'
            : totalClaimed === 1
            ? 'One person already owns this'
            : `${totalClaimed} people already own this`;

    return (
        <div className={`FeaturedPoetInfo ${className}`}>
            <TextRow className='FeaturedPoetInfo-alreadyClaimed'>
                <p>{alreadyClaimedText}</p>
            </TextRow>
            <TextRow className='FeaturedPoetInfo-creator FeaturedPoetInfo-creator--col'>
                <h3>{owner}</h3>
            </TextRow>
            <div className='FeaturedPoetInto-titleRow'>
                <TextRow className='FeaturedPoetInfo-title'>
                    <h2>
                        <Link
                            to={{
                                pathname: `/poet/${contract?.address}/token/${token?.tokenId}`,
                                state: { fromHomepage: true }
                            }}
                        >
                            {token?.metadata?.name}
                        </Link>
                    </h2>
                </TextRow>
                <TextRow className='FeaturedPoetInfo-shortCount'>
                    <p>{`${totalClaimed} of ${token?.claimableSupply} claimed`}</p>
                </TextRow>
            </div>
            <TextRow className='FeaturedPoetInfo-creator FeaturedPoetInfo-creator--row'>
                <h3>{owner}</h3>
            </TextRow>
            <Text className='FeaturedPoetInfo-description'>
                <Expandable
                    collapsedClass='FeaturedPoetInfo-descCollapsed'
                    moreClass='FeaturedPoetInfo-readMore'
                    moreText='Read More'
                    onMore={() =>
                        history.push({
                            pathname: `/poet/${contract?.address}/token/1`,
                            state: { fromHomepage: true }
                        })
                    }
                >
                    <p>{token?.metadata.description}</p>
                </Expandable>
            </Text>
            <TextRow className='FeaturedPoetInfo-showDetails FeaturedPoetInfo-showDetails--col'>
                <Link
                    to={{
                        pathname: `/poet/${contract?.address}/token/${token?.tokenId}`,
                        state: { fromHomepage: true }
                    }}
                >
                    <p>
                        Show Details <IoChevronForwardCircle />
                    </p>
                </Link>
            </TextRow>
            <TextRow className='FeaturedPoetInfo-featuredPoetMintButton'>
                <ClaimPoet
                    claimedCt={totalClaimed}
                    connectText='Connect Wallet to Claim'
                    contractAddress={contract?.address}
                    onSuccess={(tokenId, contractAddress, wallet) => {
                        poetDataCtx.update(
                            {
                                contract: contractAddress,
                                tokenId
                            },
                            {
                                claimableSupply: token.claimableSupply - 1,
                                ownerAddresses: [
                                    ...token.ownerAddresses,
                                    wallet
                                ]
                            }
                        );
                    }}
                    owners={token?.ownerAddresses}
                    showCount={false}
                    supplyCt={token?.totalSupply}
                    tokenId={token?.tokenId}
                    variant='outlined'
                />
            </TextRow>
            <TextRow className='FeaturedPoetInfo-remainingStock'>
                <p>{`${token?.claimableSupply} remaining`}</p>
            </TextRow>
            <TextRow className='FeaturedPoetInfo-showDetails FeaturedPoetInfo-showDetails--row'>
                <Link
                    to={{
                        pathname: `/poet/${contract?.address}/token/${token?.tokenId}`,
                        state: { fromHomepage: true }
                    }}
                >
                    <p>
                        Show Details <IoChevronForwardCircle />
                    </p>
                </Link>
            </TextRow>
        </div>
    );
}
