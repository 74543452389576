export default function Learn() {
    return (
        <div className='flex flex-col p-4 max-w-3xl'>
            <p className='py-4 text-3xl font-bold'>About Us:</p>
            <p className='pb-4'>
                A <b>POET</b> is a <b>Proof of Experience Token</b> that can
                document and represent the most important and interesting
                moments of your life. From everyday moments like dinner with
                friends to life-changing experiences like traveling the world,
                you can preserve and share these moments, memories, and
                experiences as NFTs (non-fungible tokens). Whether you’re
                creating an NFT just for yourself — a one of one — or you’re
                commemorating a weekend with friends or a large event where the
                maximum supply is in the hundreds, creating your <b>POET</b> is
                easy, fast, and free. All you need to get started is your crypto
                wallet and we’ll help you capture, preserve, and share your
                experience on an energy-efficient blockchain.
            </p>
            <p className='py-4 text-3xl font-bold'>FAQ:</p>
            <p className='font-bold'>1. What is a POET?</p>
            <p className='p-4'>
                A POET or Proof of Experience Token is simply a photo or gif
                taken and minted to show that you and your friends shared an
                experience. The max file size is 10MB.
            </p>
            <p className='font-bold'>2. What’s an NFT?</p>
            <p className='p-4'>
                An NFT is a Non-Fungible Token that represents a unique asset
                that can’t be exchanged or replaced by something else. Unlike a
                Bitcoin — which is fungible and one can easily be exchanged for
                another — an NFT is like a one-of-a-kind piece of art. While
                there may be several copies of the artwork, the original is
                uniquely valuable and thanks to blockchain technology, there is
                a public ledger (or record) of the NFT from the moment it is
                created that tracks every transaction and every subsequent
                owner.
            </p>
            <p className='font-bold'>3. Why should I make a POET?</p>
            <p className='p-4'>
                Making a POET is not unlike going and getting prints for a photo
                album, but made digital. Back before Instagram, (I know, it’s
                hard to remember) you’d go to a store, get your photos
                developed, and you’d be sure to make enough copies for grandma
                and grandpa. Now, you can share memories the same way, with
                POETs. You simply upload a photo, gif, or video of your
                experience, set the number of POETs that people can claim, and
                share it with them to be minted forever as NFTs on the
                blockchain.
            </p>
            <p className='font-bold'>4. What wallets are supported for POET?</p>
            <p className='p-4'>
                Rainbow, Metamask, Trust Wallet and more than 75 others thanks
                to{' '}
                <a
                    href='https://walletconnect.com/'
                    target='_blank'
                    rel='noopener noreferrer'
                >
                    Wallet Connect.
                </a>
            </p>
            <p className='font-bold'>
                5. How do I get a Rainbow or Metamask wallet?
            </p>
            <p className='p-4'>
                It’s super simple! Here’s both Metamask and Rainbow’s site that
                walks you through everything you need to know to get a wallet:
                <br />
                <b>
                    &nbsp;&nbsp;
                    <a
                        href='https://learn.rainbow.me/create-backup-your-first-ethereum-web3-wallet'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Download Rainbow
                    </a>
                    <br />
                    &nbsp;&nbsp;
                    <a
                        href='https://metamask.io/download'
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        Download Metamask
                    </a>
                </b>
            </p>
            <p className='font-bold'>6. Who can claim the NFTs I create?</p>
            <p className='p-4'>
                When you make a POET, you’ll get a QR code. Anyone who scans
                that QR Code will be able to connect their wallet, and claim
                their NFT. Only one POET can be claimed per wallet, so don’t
                worry about people trying to double-dip.
            </p>
            <p className='font-bold'>7. Where do I see my POETs?</p>
            <p className='p-4'>
                After you’ve connected your wallet, click in the upper right
                corner to view all the POETs you’ve created and claimed.
            </p>
        </div>
    );
}
