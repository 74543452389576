import Alert from '@mui/material/Alert';
import issue1155 from '../../Requests/issue-1155';
import issueNft from '../../Requests/issue-nft';
import LoginSomehow from '../LoginSomehow';
import RemWalletContext from '../../Contexts/rem-wallet-context.js';
import Text from '../MuiAdapters/Text.js';
import useRemoteValue from '../../utils/use-remote-value';

import { ContractDataContext } from '../ContractDataProvider';
import { createRef, useContext, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { PoetDataContext } from '../PoetDataProvider.js';
import { ReCAPTCHAContext } from '../App.js';
import { When } from 'react-if';

export default function ClaimPoet({
    claimedCt,
    connectText,
    contractAddress,
    onSuccess,
    owners = [],
    showCount = true,
    supplyCt,
    tokenId,
    variant = 'contained'
}) {
    const contractDataCtx = useContext(ContractDataContext);
    const [contract, provisional] = useRemoteValue(
        ContractDataContext,
        contractAddress
    );
    const recaptcha = useContext(ReCAPTCHAContext);

    if (contract.numTokens && tokenId === undefined) {
        throw new Error('tokenId must be set on 1155 POET');
    }

    const soldOut = claimedCt === supplyCt;

    const poetDataCtx = useContext(PoetDataContext);
    const remWalletCtx = useContext(RemWalletContext);

    const [isClaiming, setIsClaiming] = useState(false);
    const [issueNftFailed, setIssueNftFailed] = useState(false);

    const owned = owners.some(w => remWalletCtx.isConnectedUser(w));

    const claimable = !provisional && !owned && !soldOut;
    const text = isClaiming
        ? 'Claiming...'
        : provisional
        ? 'Retrieving Data...'
        : owned
        ? 'You already own this'
        : soldOut
        ? 'All Out, Sorry!'
        : 'Claim';

    const claimOrSignin = remWalletCtx.available ? (
        <>
            <When condition={showCount}>
                <Text className='ClaimPoet-supply' variant='poetQuantity'>
                    Total Claimed: {claimedCt} {supplyCt && ` of ${supplyCt}`}
                </Text>
            </When>
            <div className='ClaimPoet-claimBtn'>
                <LoadingButton
                    onClick={async e => {
                        e.preventDefault();

                        const captchaToken = await recaptcha.executeAsync();

                        setIsClaiming(true);
                        setIssueNftFailed(false);
                        try {
                            const knownData = await mint(
                                contract,
                                tokenId,
                                captchaToken,
                                remWalletCtx
                            );

                            poetDataCtx.update(
                                {
                                    contract: contractAddress,
                                    tokenId: knownData.tokenId
                                },
                                knownData
                            );

                            if (tokenId === undefined) {
                                // Old-style 721 POET.
                                contractDataCtx.update(contractAddress, {
                                    totalClaimed: contract.claimedCt + 1
                                });
                            }

                            onSuccess(
                                knownData.tokenId,
                                contractAddress,
                                remWalletCtx.actingWallet
                            );
                        } catch (e) {
                            console.log(e);
                            setIssueNftFailed(true);
                        }

                        setIsClaiming(false);
                    }}
                    disabled={!claimable || owned}
                    loading={provisional || isClaiming}
                    loadingIndicator={text}
                    fullWidth
                    variant={variant}
                    color='primary'
                    size='large'
                >
                    {text}
                </LoadingButton>
            </div>
        </>
    ) : (
        <LoginSomehow connectText={connectText} />
    );

    const errorMsg = issueNftFailed ? (
        <div className='ClaimPoet-alert'>
            <Alert onClose={() => setIssueNftFailed(false)} severity='error'>
                Something went wrong, please try again. Note that only one POET
                can be minted per wallet
            </Alert>
        </div>
    ) : null;

    return (
        <>
            {claimOrSignin}
            {errorMsg}
        </>
    );
}

async function mint(contract, tokenId, captchaToken, remWalletCtx) {
    let result;

    if (tokenId !== undefined) {
        // New 1155-style POET.
        await issue1155(
            contract.address,
            tokenId,
            captchaToken,
            {
                toAddress: remWalletCtx.web3Wallet,
                userId: remWalletCtx.auth0Login?.sub
            },
            {
                headers: remWalletCtx.headers
            }
        );

        result = {
            contractAddress: contract.address,
            metadataJson: contract.metadataJson,
            metadataUri: contract.metadataUri,
            tokenId
        };
    } else {
        // Old 721-style POET.
        const { res, error } = await issueNft(
            {
                contractAddress: contract.address,
                toAddress: remWalletCtx.web3Wallet,
                key: remWalletCtx.urlWallet,
                metadataUri: contract.metadataUri,
                userId: remWalletCtx.auth0Login?.sub,
                captchaToken
            },
            {
                headers: remWalletCtx.headers
            }
        );

        if (!res.success) {
            throw new Error(error);
        }

        result = {
            contractAddress: contract.address,
            metadata: contract.metadata,
            metadataUri: contract.metadataUri,
            ownerAddress: remWalletCtx.actingWallet,
            tokenId: res.tokenId
        };
    }

    return result;
}
