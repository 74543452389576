import useRemoteValue from './use-remote-value.js';

import { PoetDataContext } from '../Components/PoetDataProvider';
import { useMemo } from 'react';

export default function useTokenData(contractAddress, tokenId) {
    const [nftData, nftDataProvisional] = useRemoteValue(
        PoetDataContext,
        useMemo(
            () => ({ contract: contractAddress, tokenId: tokenId }),
            [contractAddress, tokenId]
        )
    );

    const parsedMetadata = useMemo(
        () => JSON.parse(nftData.metadata || '{}'),
        [nftData?.metadata]
    );

    return [{ ...nftData, metadata: parsedMetadata }, nftDataProvisional];
}
