import { Link } from 'react-router-dom';

import './Faq.css';

const contactHref = 'https://bandwagonfanclub.com/contact/';
const signUpHref =
    'https://docs.google.com/forms/d/13IpAVg80FcwlULweqHfrcq4fHaPQoL8klkD7KRv4dMM';

export default function Faq() {
    return (
        <main className='DayOnesFaq'>
            <h1>Frequently Asked Questions</h1>
            <section>
                <h2>For Musicians and Artists</h2>
                <section>
                    <h3>What is The Day Ones collection?</h3>
                    <p>
                        The Day Ones Collection allows musicians to create
                        mintable badges, providing fans with exclusive access to
                        music drops, swag, and other unique content. By
                        onboarding fans, artists can also earn financial
                        rewards.
                    </p>
                </section>
                <section>
                    <h3>How can I join the program?</h3>
                    <p>
                        You can join the program by{' '}
                        <a href={signUpHref}>signing up on our website</a> and
                        following the step-by-step guide to create your mintable
                        tokens.
                    </p>
                </section>
                <section>
                    <h3>How do I earn $2500?</h3>
                    <p>
                        For every 1000 fans that you onboard through your POE
                        Badges, you will earn $2500.
                    </p>
                </section>
                <section>
                    <h3>Is there any technical expertise required?</h3>
                    <p>
                        No, Proof of Experience™ is designed to be
                        user-friendly, and we provide support to assist you.
                    </p>
                </section>
                <section>
                    <h3>What about copyright and intellectual property?</h3>
                    <p>
                        All content shared through your mintable badges remains
                        the intellectual property of the artist.&nbsp;
                        <Link to='/terms'>Our terms and conditions</Link>{' '}
                        outline the full details regarding copyright.
                    </p>
                </section>
            </section>
            <section>
                <h2>For Fans</h2>
                <section>
                    <h3>What are fan badges, and how do they work?</h3>
                    <p>
                        Fan badges are unique digital assets created by your
                        favorite artist to provide you with special access to
                        content, events, or merchandise. By claiming your badge,
                        you’ll unlock exclusive experiences not immediately
                        shared with the public.
                    </p>
                </section>
                <section>
                    <h3>How can I purchase or access my badges?</h3>
                    <p>
                        You can browse available tokens or badges from your
                        favorite artists on <Link to='/'>poe.xyz</Link> and
                        follow the prompts to purchase or gain access.
                    </p>
                </section>
                <section>
                    <h3>Is my personal information safe?</h3>
                    <p>
                        Yes, we adhere to strict privacy regulations and use
                        secure technologies to protect your personal
                        information. We are not responsible for any information
                        shared on the Blockchain.
                    </p>
                </section>
                <section>
                    <h3>What if I have an issue with a purchase or access?</h3>
                    <p>
                        Our support team is available to assist with any issues
                        or concerns. You can contact us through&nbsp;
                        <a href='mailto:hey@bandwagonfanclub.com'>
                            hey@bandwagonfanclub.com
                        </a>
                        .
                    </p>
                </section>
                <section>
                    <h3>Can I share or transfer my badge to someone else?</h3>
                    <p>
                        The ability to transfer your badge depends on the
                        individual terms set by the artist. Please refer to the
                        specific token details for more information.
                    </p>
                </section>
            </section>
            <section>
                <h2>General Questions</h2>
                <section>
                    <h3>
                        Is POE's The Day Ones Collection available worldwide?
                    </h3>
                    <p>
                        Yes, our platform is accessible to artists and fans
                        globally, unless otherwise restricted by local
                        regulations.
                    </p>
                </section>
                <section>
                    <h3>What devices and browsers are supported?</h3>
                    <p>
                        POE's platform is optimized for modern browsers and
                        accessible on various devices, including smartphones,
                        tablets, and desktop computers.
                    </p>
                </section>
                <section>
                    <h3>Who can I contact if I have more questions?</h3>
                    <p>
                        For any additional inquiries, please reach out to our
                        support team at&nbsp;
                        <a href='mailto:hey@bandwagonfanclub.com'>
                            hey@bandwagonfanclub.com
                        </a>
                        , or you can visit{' '}
                        <a href={contactHref}>our contact page</a>.
                    </p>
                </section>
            </section>
        </main>
    );
}
