import { Link } from 'react-router-dom';

import RemWalletContext from '../Contexts/rem-wallet-context.js';
import { Button } from '@mui/material';

import { useContext } from 'react';

import { useWeb3Modal } from '@web3modal/react';

import './ConnectWallet.css';

function ConnectWallet(props) {
    const { open } = useWeb3Modal();
    const remWalletCtx = useContext(RemWalletContext);

    return remWalletCtx.web3Wallet ? (
        <Link to={`/wallet/${remWalletCtx.web3Wallet}`}>
            <Button {...props} variant='outlined'>
                {remWalletCtx.displayName}
            </Button>
        </Link>
    ) : (
        <Button
            {...props}
            className='ConnectWallet-connectWalletBtn'
            variant='contained'
            onClick={() => {
                open();
            }}
        >
            {props.connectText || 'Connect Wallet'}
        </Button>
    );
}

export default ConnectWallet;
