import graphqlRequest from './graphql-request';
import lodash from 'lodash';

export default async function deployContract(variables, options) {
    const result = await graphqlRequest(
        `mutation DeployContract(
             $metadata: String!
             $perTokenSupply: Int!
             $tokenImageUris: [String!]!
             $userId: String
             $userWallet: String
             $captchaToken: String!
             $userMetadataField: String
         ) { deploy1155Contract (
                 metadata: $metadata,
                 perTokenSupply: $perTokenSupply,
                 tokenImageUris: $tokenImageUris,
                 userId: $userId,
                 userWallet: $userWallet,
                 userMetadataField: $userMetadataField
                 captchaToken: $captchaToken
             ) {
                 success
                 contract {
                     address
                     metadataUri
                 }
             }
      }`,
        {
            userMetadataField: 'poetOwner',

            ...variables
        },
        options
    );

    const res = result.res;
    const error = result.error;
    const { success, contract } = lodash.get(res, 'deploy1155Contract', {});
    if (success) {
        return {
            res: {
                success,
                contract: {
                    contractAddress: contract.address,
                    metadataUri: contract.metadataUri
                }
            },
            error
        };
    }

    return {
        res: {
            success: false,
            contract: {}
        },
        error
    };
}
