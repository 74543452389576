import axios from 'axios';
import React from 'react';
import RemoteValueProvider from './RemoteValueProvider';
import lodash from 'lodash';

export const PoetDataContext = React.createContext();

const PoetDataProvider = ({ children }) => (
    <RemoteValueProvider
        context={PoetDataContext}
        keySerializer={
            // Ensure a consistent order
            ({ contract, tokenId }) => JSON.stringify({ contract, tokenId })
        }
        fetch={async key => {
            const { contract, tokenId } = key;

            const { data = {}, errors = [] } = await axios.post(
                `${window.__RUNTIME_CONFIG__.REACT_APP_REMINISCERATOR_ENDPOINT}/graphql`,
                {
                    query: `
                            query GetNft(
                                $contractAddress: String!
                                $tokenId: Int!
                            ) {
                                nft(
                                    contractAddress: $contractAddress,
                                    tokenId: $tokenId
                                ) {
                                    success
                                    nft {
                                      claimableSupply
                                      contractAddress
                                      metadataUri
                                      metadata
                                      ownerAddress
                                      ownerAddresses
                                      tokenId
                                      totalOwned
                                      totalSupply
                                    }
                                }
                            }
                        `,
                    variables: {
                        contractAddress: contract,
                        tokenId
                    }
                }
            );

            return [lodash.get(data, 'data.nft.nft'), errors];
        }}
    >
        {children}
    </RemoteValueProvider>
);

export default PoetDataProvider;
