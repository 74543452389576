import graphqlRequest from './graphql-request';
import lodash from 'lodash';

export default async function getContracts(variables) {
    const { res, error } = await graphqlRequest(
        `
      query GetContracts(
        $metadataPredicates: [Predicate!]!
        $first: Int!
        $after: String!
        $sort: SortOperation
      ) {
        contractsV2(
          metadataPredicates: $metadataPredicates
          first: $first
          after: $after
          sortOperation: $sort
        ) {
          success
          edges {
            node {
              ... on Contract721 {
                address
                annotationsJson
                metadataUri
                metadataJson
              }
              ... on Contract1155 {
                address
                annotationsJson
                metadataUri
                metadataJson
                numTokens
              }
            }
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
      }
    `,
        variables
    );

    return {
        res: lodash.get(res, 'contractsV2', {}),
        error
    };
}
