import './login-somehow.css';

import ConnectWallet from './ConnectWallet.js';
import PoetThemeContext from '../Contexts/poet-theme-context.js';
import RemWalletContext from '../Contexts/rem-wallet-context.js';
import ViewWalletPageButton from './ViewWalletPageButton';

import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext } from 'react';
import { Case, Default, Switch } from 'react-if';

function LoginSomehow(props) {
    const { isLoading } = useAuth0();

    const remWalletCtx = useContext(RemWalletContext);
    const theme = useContext(PoetThemeContext);

    return (
        <div className='LoginSomehow'>
            <Switch>
                <Case condition={isLoading}>
                    <span>Loading...</span>
                </Case>
                <Case condition={remWalletCtx.available}>
                    <ViewWalletPageButton />
                </Case>
                <Default>
                    <div className='LoginSomehow-loginButtons'>
                        <div className='LoginSomehow-buttonContainer'>
                            <Button
                                fullWidth={true}
                                variant={
                                    theme.variants.socialLoginButton || 'text'
                                }
                                onClick={() => remWalletCtx.loginWithState()}
                            >
                                Log In Without a Wallet
                            </Button>
                        </div>
                        <div className='LoginSomehow-buttonContainer'>
                            <ConnectWallet
                                fullWidth={true}
                                connectText={props.connectText}
                            />
                        </div>
                    </div>
                </Default>
            </Switch>
        </div>
    );
}

export default LoginSomehow;
