import Button from '@mui/material/Button';
import ClassBySize from '../../Utils/ClassBySize.jsx';
import Fade from 'react-reveal/Fade';
import FeaturedPoetInfo from './FeaturedPoetInfo.jsx';
import FeaturedPoetInfoCard from './FeaturedPoetInfoCard.jsx';
import getContracts from '../../../Requests/get-contracts';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PoetDetail from '../../PoetDetail/PoetDetail.js';
import PoetThemeContext from '../../../Contexts/poet-theme-context.js';
import ReactPlaceholder from 'react-placeholder';
import ReactTypingEffect from 'react-typing-effect';
import useRemoteValue from '../../../utils/use-remote-value';
import useSize from '@react-hook/size';
import useTokenData from '../../../utils/use-token-data.jsx';

import { ContractDataContext } from '../../ContractDataProvider';
import { PoetDataContext } from '../../PoetDataProvider';
import {
    Suspense,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { When } from 'react-if';
import { useImage } from 'react-image';
import { useHistory, useParams } from 'react-router-dom';

import 'react-placeholder/lib/reactPlaceholder.css';
import './Homepage.css';

export default function Homepage() {
    const [tokens, setTokens] = useState([]);

    const poetTheme = useContext(PoetThemeContext);
    const routeParams = useParams();

    const ref = useRef();
    const [homepageWidth] = useSize(ref);

    useEffect(() => {
        (async () => {
            setTokens(await getFeaturedTokens(poetTheme));
        })();
    }, [poetTheme, setTokens]);

    const [open, setOpen] = useState(true);

    return (
        <div ref={ref} className='Homepage'>
            <ClassBySize
                className='Homepage-fold Homepage-fold--hero'
                sizeToClass={(width, height) =>
                    width > 800 ? 'Homepage-fold--row' : 'Homepage-fold--col'
                }
            >
                <div className='Homepage-foldHeroText'>
                    <ReactTypingEffect
                        className='Homepage-foldHeroType'
                        text={['Collect.', 'Create.', 'Share.']}
                        speed={75}
                        eraseSpeed={50}
                        eraseDelay={2500}
                        typingDelay={1000}
                    />
                    <p className='Homepage-foldHeroStatic'>Your Experiences.</p>
                </div>
            </ClassBySize>
            <When condition={tokens.length === 0}>
                <PlaceholderPoetContract />
            </When>
            {tokens.map(c => (
                <FeaturedPoetContract
                    key={`${c.address}_${c.featuredTokenId}`}
                    contract={c}
                />
            ))}
            <When condition={routeParams.address}>
                () =>{' '}
                <FeaturedPoetModal
                    contractAddress={routeParams.address}
                    tokenId={routeParams.tokenId}
                    homepageWidth={homepageWidth}
                />
            </When>
        </div>
    );
}

function FeaturedPoetModal({ contractAddress, homepageWidth, routeParams }) {
    const history = useHistory();
    const params = useParams();

    const [contractData, contractDataProvisional] = useRemoteValue(
        ContractDataContext,
        params.address
    );
    const [nftData, nftDataProvisional] = useRemoteValue(
        PoetDataContext,
        useMemo(
            () => ({
                contract: params.address,
                tokenId: parseInt(params.tokenId)
            }),
            [params.address, params.tokenId]
        )
    );

    return (
        <Dialog
            fullScreen={homepageWidth < 600}
            open={true}
            onClose={() => history.push('/')}
            maxWidth='lg'
            fullWidth
            aria-labelledby='Homepage-poetModalTitle'
            aria-describedby='Homepage-poetModalDescription'
        >
            <DialogContent>
                <PoetDetail />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => history.push('/')}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

function PlaceholderPoetContract() {
    return (
        <ClassBySize
            className='Homepage-fold'
            sizeToClass={(width, height) =>
                width > 800 ? 'Homepage-fold--row' : 'Homepage-fold--col'
            }
        >
            <div className='Homepage-featuredPoetContract'>
                <div className='Homepage-imageContainer'>
                    <ReactPlaceholder
                        showLoadingAnimation={true}
                        type='rect'
                        ready={false}
                    />
                </div>
                <div className='Homepage-infoContainer'>
                    <FeaturedPoetInfoCard
                        className='Homepage-infoCard'
                        contract={null}
                        variant='row'
                    />
                    <FeaturedPoetInfo
                        className='Homepage-infoNoCard FeaturedPoetInfo--col'
                        contract={null}
                    />
                </div>
            </div>
        </ClassBySize>
    );
}

function FeaturedPoetContract({ contract }) {
    const [nftData, nftDataProvisional] = useTokenData(
        contract.address,
        contract.featuredTokenId
    );

    if (Object.keys(nftData.metadata).length === 0) {
        if (nftDataProvisional) {
            return <PlaceholderPoetContract />;
        } else {
            return null;
        }
    }

    return (
        <Suspense fallback={<PlaceholderPoetContract />}>
            <ClassBySize
                className='Homepage-fold'
                sizeToClass={(width, height) =>
                    width > 800 ? 'Homepage-fold--row' : 'Homepage-fold--col'
                }
            >
                <Fade>
                    <div className='Homepage-featuredPoetContract'>
                        <div className='Homepage-imageContainer'>
                            <Img
                                url={nftData?.metadata?.image}
                                className='Homepage-image'
                            />
                        </div>
                        <div className='Homepage-infoContainer'>
                            <FeaturedPoetInfoCard
                                className='Homepage-infoCard'
                                contract={contract}
                                token={nftData}
                                variant='row'
                            />
                            <FeaturedPoetInfo
                                className='Homepage-infoNoCard FeaturedPoetInfo--col'
                                contract={contract}
                                token={nftData}
                            />
                        </div>
                    </div>
                </Fade>
            </ClassBySize>
        </Suspense>
    );
}

function Img({ url, className }) {
    const { src } = useImage({ srcList: url });
    return <img alt='A user-submitted POET' className={className} src={src} />;
}

async function getFeaturedTokens(poetTheme) {
    const predicates = [
        {
            operation: 'TEXT_SEARCH',
            fieldName: 'poetOwner',
            fieldValue: '0x'
        },
        ...(poetTheme.homepagePredicates ?? [])
    ];

    const { res, error } = await getContracts({
        metadataPredicates: predicates,
        first: 10,
        after: '',
        sortOperation: 'BLOCK_DESC'
    });

    if (error && !res) {
        return { error };
    }

    const featuredTokens = (res.edges || [])
        .map(({ node }) => {
            const metadata = JSON.parse(node.metadataJson || '{}');
            const annotations = JSON.parse(node.annotationsJson || '{}');

            let featuredTokens = annotations?.featuredTokenId ?? 1;
            if (!Array.isArray(featuredTokens)) {
                featuredTokens = [featuredTokens];
            }
            featuredTokens = featuredTokens.map(t => {
                if (typeof t !== 'object') {
                    t = { id: t };
                }

                if (!t.weight) {
                    t.weight = 0;
                }

                return t;
            });

            return featuredTokens.map(({ id, weight }) => ({
                ...node,
                featuredTokenId: id,
                featuredTokenWeight: weight,
                metadata
            }));
        })
        .flat();

    featuredTokens.sort(
        ({ featuredTokenWeight: w1 }, { featuredTokenWeight: w2 }) => w2 - w1
    );

    return featuredTokens;
}
