import {
    Card,
    Button,
    Typography as Font,
    FormHelperText
} from '@mui/material';
import PoetThemeContext from '../../Contexts/poet-theme-context';
import ThemeProvider from '../ThemeProvider.js';
import { useContext } from 'react';

import './Uploader.css';

export default function Uploader(props) {
    const { errorMessage, handleFileInput } = props;
    const theme = useContext(PoetThemeContext);

    return (
        <ThemeProvider.Subtheme themeName='Uploader'>
            <label className='Uploader'>
                <Card
                    variant='uploader'
                    className={`Uploader-card ${
                        errorMessage && 'border border-red-600'
                    }`}
                >
                    <Button
                        component='span'
                        className='Uploader-uploadButton'
                        variant='contained'
                    >
                        Upload
                    </Button>
                    <div className='Uploader-cta'>
                        <Font variant='body1' className='px-4 text-center'>
                            Upload up to 10 images/GIFs (max file size 10MB)
                        </Font>
                    </div>
                </Card>
                {errorMessage && (
                    <FormHelperText error={!!errorMessage}>
                        {errorMessage}
                    </FormHelperText>
                )}
                <input
                    className='hidden'
                    type='file'
                    onChange={handleFileInput}
                    accept='image/*'
                    multiple
                />
            </label>
        </ThemeProvider.Subtheme>
    );
}
