import { FadeLoader } from 'react-spinners';
import PoetThemeContext from '../Contexts/poet-theme-context';
import { useContext } from 'react';

export default function Loader() {
    const theme = useContext(PoetThemeContext);
    return (
        <FadeLoader
            cssOverride={{
                display: 'block'
            }}
            color={theme.mui.palette.primary.main}
            height={30}
            width={7}
            margin={30}
        />
    );
}
