// Modified, non-TS version of
// https://github.com/nygardk/react-share/blob/master/src/TwitterShareButton.ts
// patched with this PR:
// https://github.com/nygardk/react-share/pull/485

import assert from 'assert';
import objectToGetParams from 'react-share/lib/utils/objectToGetParams';
import createShareButton from 'react-share/lib/hocs/createShareButton';

function twitterLink(url, { title, via, hashtags = [], related = [] }) {
    assert(url, 'twitter.url');
    assert(Array.isArray(hashtags), 'twitter.hashtags is not an array');
    assert(Array.isArray(related), 'twitter.related is not an array');

    return (
        'https://twitter.com/intent/tweet' +
        objectToGetParams({
            url,
            text: title,
            via,
            hashtags: hashtags.length > 0 ? hashtags.join(',') : undefined,
            related: related.length > 0 ? related.join(',') : undefined
        })
    );
}

const PatchedTwitterShareButton = createShareButton(
    'twitter',
    twitterLink,
    props => ({
        hashtags: props.hashtags,
        title: props.title,
        via: props.via,
        related: props.related
    }),
    {
        windowWidth: 550,
        windowHeight: 400
    }
);

export default PatchedTwitterShareButton;
