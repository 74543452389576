import PoetThemeContext from '../../Contexts/poet-theme-context.js';
import RemWalletContext from '../../Contexts/rem-wallet-context.js';
import ThemeProvider from '../ThemeProvider';
import ViewWalletPageButton from '../ViewWalletPageButton';

import { Link } from 'react-router-dom';
import {
    Button as MuiButton,
    IconButton,
    Paper,
    Typography as Font
} from '@mui/material';
import { HighlightOff, Menu } from '@mui/icons-material';
import { useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { When } from 'react-if';

import './Header.css';

function Header({ showCreate = true }) {
    const poetTheme = useContext(PoetThemeContext);
    const remWalletCtx = useContext(RemWalletContext);
    const location = useLocation();
    const [showMenu, setShowMenu] = useState(false);

    const createPage = poetTheme?.features?.homepage ? '/create' : '/';

    return (
        <>
            <ThemeProvider.Subtheme themeName='Header' className='Header'>
                <Paper className='Header-base p-4 header-border md:border-0 md:shadow-none'>
                    {poetTheme.assets.FullSizeLogoImage && (
                        <img
                            className={
                                poetTheme.classNames.logo ||
                                'hidden md:flex w-52 h-20 object-scale-down'
                            }
                            src={poetTheme.assets.FullSizeLogoImage}
                            alt='poet logo and home button'
                        />
                    )}
                    {poetTheme.assets.CompactLogoImage && (
                        <img
                            className={
                                poetTheme.classNames.logoMobile ||
                                'flex md:hidden w-14'
                            }
                            src={poetTheme.assets.CompactLogoImage}
                            alt='poet logo and home button'
                        />
                    )}
                    <div className='Header-navigationContainer'>
                        <NavigationItems poetTheme={poetTheme} />
                    </div>
                    <Link
                        to={createPage}
                        className={`Header-navCreateButtonLink ${
                            showCreate ? '' : 'u-invisible'
                        }`}
                    >
                        <MuiButton
                            variant='contained'
                            className='Header-createButton'
                        >
                            Create
                        </MuiButton>
                    </Link>
                    {showMenu && (
                        <Paper elevation={3} className='Header-menuContainer'>
                            <div className='Header-hideMenuButtonContainer'>
                                <IconButton
                                    className='Header-hideMenuButton'
                                    onClick={() => {
                                        setShowMenu(false);
                                    }}
                                >
                                    <HighlightOff
                                        fontSize='large'
                                        color='primary'
                                    />
                                </IconButton>
                            </div>
                            <NavigationItems poetTheme={poetTheme} />
                            <Link
                                to={createPage}
                                className='Header-menuCreateButtonLink'
                            >
                                <MuiButton
                                    variant='contained'
                                    className='Header-createButton'
                                >
                                    Create
                                </MuiButton>
                            </Link>
                        </Paper>
                    )}
                    {!showMenu && (
                        <IconButton
                            className='Header-menuButton'
                            onClick={() => {
                                setShowMenu(true);
                            }}
                        >
                            <Menu fontSize='large' color='primary' />
                        </IconButton>
                    )}
                </Paper>
            </ThemeProvider.Subtheme>
            <hr className='Header-mobileDivider' />
        </>
    );
}

function LoggedInControls() {
    const remWalletCtx = useContext(RemWalletContext);
    const { wallet: viewWallet } = useParams();

    return (
        <div className='Header-loggedInControls'>
            {!viewWallet || !remWalletCtx.isConnectedUser(viewWallet) ? (
                // We're not in the connected user's wallet context.
                <ViewWalletPageButton />
            ) : (
                // We -are- in the connected user's wallet context.
                <MuiButton
                    variant='text'
                    poetSubtheme='header'
                    className='mx-5'
                    onClick={() => {
                        remWalletCtx.logout();
                    }}
                >
                    {remWalletCtx.auth0Login ? 'Log out' : 'Disconnect'}
                </MuiButton>
            )}
        </div>
    );
}

function NavigationItems({ poetTheme }) {
    const remWalletCtx = useContext(RemWalletContext);
    const path = window.location.pathname;

    return (
        <ul className={`Header-navigationItems`}>
            <When condition={poetTheme.features.homepage}>
                <Link to={'/'}>
                    <li className='Header-navItem'>
                        <Font
                            variant='headerLink'
                            className={
                                path === '/' ? 'Header-navItemFocused' : ''
                            }
                        >
                            Home
                        </Font>
                    </li>
                </Link>
            </When>
            {remWalletCtx.available && (
                <Link to={`/wallet/${remWalletCtx.urlWallet}`}>
                    <li className='Header-navItem'>
                        <Font
                            variant='headerLink'
                            className={
                                path === `/wallet/${remWalletCtx.urlWallet}`
                                    ? 'Header-navItemFocused'
                                    : ''
                            }
                        >
                            My POETS
                        </Font>
                    </li>
                </Link>
            )}
            <Link to={'/learn'}>
                <li className='Header-navItem'>
                    <Font
                        variant='headerLink'
                        className={
                            path === '/learn' ? 'Header-navItemFocused' : ''
                        }
                    >
                        FAQ
                    </Font>
                </li>
            </Link>
        </ul>
    );
}

export default Header;
