import useSize from '@react-hook/size';

import { useRef } from 'react';

// We try to eschew media queries (which function on view size) in favor of
// layout by component size (besides being intuitive this is encouraged by our
// SUIT css style--components should just do their best to fill their assigned
// rectangle.) In an ideal world, we'd use pure CSS for this sort of thing, but
// even with grid and flexbox, some things require truly ridiculous hoops and
// others are simply impossible (see this post, updated 03-27-2023:
// https://stackoverflow.com/a/42176548/1623260). Luckily, a little bit of
// javascript can save the day. Assign different classes based on size.
export default function ClassBySize({ children, className = '', sizeToClass }) {
    const ref = useRef();
    const [divWidth, divHeight] = useSize(ref);
    const dynamicClassName = sizeToClass(divWidth, divHeight) ?? '';

    return (
        <div ref={ref} className={className + ' ' + dynamicClassName}>
            {children}
        </div>
    );
}
