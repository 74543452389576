import ClaimPoet from '../Utils/ClaimPoet';
import Loader from '../Loader';
import lodash from 'lodash';
import PoetThemeContext from '../../Contexts/poet-theme-context.js';
import Share from '../Utils/Share';
import useDisplayedAddress from '../../utils/use-displayed-address';
import useRemoteValue from '../../utils/use-remote-value';
import ShareWidget from './ShareWidget';
import RemWalletContext from '../../Contexts/rem-wallet-context';
import Tags from '../Utils/Tags.jsx';

import { ContractDataContext } from '../ContractDataProvider';
import { Link as ExternalLink } from '@mui/material';
import { PoetDataContext } from '../PoetDataProvider';
import { Typography as Font } from '@mui/material';
import { useContext, useMemo } from 'react';
import { useHistory, useParams, Link as InternalLink } from 'react-router-dom';
import { When } from 'react-if';

import './PoetDetail.css';

function explorerHref(contractAddress) {
    return window.__RUNTIME_CONFIG__.EXPLORER_URL_TEMPLATE.replace(
        new RegExp('{{contractAddress}}', 'g'),
        contractAddress
    );
}

const DEFAULT_SHARE_TEXT = 'Check out my POET!';

export default function Detail({ variant = 'vertical' }) {
    const theme = useContext(PoetThemeContext);
    const wallet = useContext(RemWalletContext);
    const poetDataCtx = useContext(PoetDataContext);

    const history = useHistory();
    const params = useParams();
    const contractAddress = useParams().address;

    const [contractData, contractDataProvisional] = useRemoteValue(
        ContractDataContext,
        params.address
    );
    const [nftData, nftDataProvisional] = useRemoteValue(
        PoetDataContext,
        useMemo(
            () => ({
                contract: params.address,
                tokenId: parseInt(params.tokenId)
            }),
            [params.address, params.tokenId]
        )
    );

    const claimable = nftData.claimableSupply > 0 && nftData.metadata;

    const nftMetada = useMemo(
        () => JSON.parse(nftData.metadata || '{}'),
        [nftData]
    );

    const owners =
        nftData.ownerAddresses ??
        (nftData.ownerAddress ? [nftData.ownerAddress] : []);

    const provisional = nftDataProvisional || contractDataProvisional;

    const loading = useMemo(
        () => Object.keys(nftData ?? {}).length === 0 && provisional,
        [nftData, provisional]
    );

    const name = lodash.get(nftMetada, 'name', '');
    const image = lodash.get(nftMetada, 'image', '');
    const description = lodash.get(nftMetada, 'description', '');
    const tags = lodash.get(nftMetada, 'tags', []);

    const qs = new URLSearchParams(window.location.search);
    const claimSuccess = qs.get('claim') === 'successful';

    const hashtags =
        (theme.strings.brandingTags ?? []).length > 0
            ? theme.strings.brandingTags
            : ['POET'];

    return (
        <div className='flex flex-col items-center'>
            {loading ? (
                <Loader />
            ) : (
                <div className='flex flex-col max-w-lg'>
                    {claimSuccess && (
                        <Font variant='h1' className='pt-10 md:pt-0 pb-5'>
                            {theme.strings.mintSuccess ||
                                'POET Successfully Claimed!'}
                        </Font>
                    )}
                    <Font variant='h1' className='PoetDetail-name text-lg py-3'>
                        {name}
                    </Font>
                    {'numTokens' in contractData && (
                        <IntraContractNavigation
                            contractData={contractData}
                            params={params}
                        />
                    )}
                    <img
                        className='w-full'
                        src={image}
                        onError={e => {
                            e.target.onerror = null;
                            e.target.src =
                                'https://www.examvuedigitalxray.com/wp-content/themes/apexclinic/images/no-image/No-Image-Found-400x264.png';
                        }}
                        alt={name}
                    />
                    <Font
                        variant='poetDescription'
                        component='p'
                        className='py-3 whitespace-pre-wrap'
                    >
                        {description}
                    </Font>
                    <When condition={tags.length > 0}>
                        <Tags tab='claimed' tags={tags} />
                    </When>
                    {owners.some(w => wallet.isConnectedUser(w)) && (
                        <>
                            <Share poetName={name} url={window.location.href} />
                            <ShareWidget
                                hashtags={hashtags}
                                text={
                                    theme.strings.socialShareText ??
                                    DEFAULT_SHARE_TEXT
                                }
                                twitterExtraText={
                                    theme.strings.twitterShareExtraText ??
                                    '@poexyz'
                                }
                            />
                        </>
                    )}
                    {'numTokens' in contractData && (
                        <ClaimPoet
                            claimable={claimable}
                            claimedCt={
                                nftData.totalSupply - nftData.claimableSupply
                            }
                            contractAddress={contractAddress}
                            onSuccess={(tokenId, contractAddress, wallet) => {
                                poetDataCtx.update(
                                    {
                                        contract: contractAddress,
                                        tokenId: tokenId
                                    },
                                    {
                                        claimableSupply:
                                            nftData.claimableSupply - 1,
                                        ownerAddresses: [
                                            ...nftData.ownerAddresses,
                                            wallet
                                        ]
                                    }
                                );
                            }}
                            owners={owners}
                            supplyCt={nftData.totalSupply}
                            tokenId={Number.parseInt(params.tokenId)}
                        />
                    )}
                    <div className='PoetDetails-owners'>
                        <Font variant='poetDescription' component='p'>
                            {owners.length === 0 ? (
                                'Owned by no one!'
                            ) : owners.length === 1 ? (
                                <>
                                    {'Owned by'}{' '}
                                    <OwnerLink wallet={owners[0]} />
                                </>
                            ) : (
                                <>
                                    {'Owners: '}
                                    {owners.map((o, i) => (
                                        <>
                                            {i !== 0 ? ', ' : ''}
                                            <OwnerLink wallet={o} />
                                        </>
                                    ))}
                                </>
                            )}
                        </Font>
                    </div>
                    <p className='py-6 self-center'>
                        <ExternalLink
                            href={explorerHref(params.address)}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='link'
                        >
                            {window.__RUNTIME_CONFIG__.EXPLORER_LINK_TEXT}
                        </ExternalLink>
                    </p>
                </div>
            )}
        </div>
    );
}

function IntraContractNavigation({ contractData, params }) {
    return (
        contractData.numTokens > 1 && (
            <div className='PoetDetail-intraContractNavigation'>
                {
                    <InternalLink
                        to={`./${parseInt(params.tokenId) - 1}`}
                        className={params.tokenId <= 1 && 'u-hidden'}
                    >
                        &lt;
                    </InternalLink>
                }
                <p>
                    Poet {params.tokenId}/{contractData.numTokens}
                </p>
                {
                    <InternalLink
                        to={`./${parseInt(params.tokenId) + 1}`}
                        className={
                            params.tokenId >= contractData.numTokens &&
                            'u-hidden'
                        }
                    >
                        &gt;
                    </InternalLink>
                }
            </div>
        )
    );
}

function OwnerLink({ wallet }) {
    const displayedAddress = useDisplayedAddress(wallet);

    return (
        <InternalLink
            className='PoetDetail-ownerLink'
            to={`/wallet/${displayedAddress}`}
        >
            {displayedAddress}
        </InternalLink>
    );
}
