import bs58 from 'bs58';
import graphqlRequest from 'graphql-request';
import PoetThemeContext from '../Contexts/poet-theme-context.js';
import RemWalletContext from '../Contexts/rem-wallet-context.js';
import useDisplayedAddress from '../utils/use-displayed-address';

import { Auth0Provider } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

import {
    EthereumClient,
    w3mConnectors,
    w3mProvider
} from '@web3modal/ethereum';

import { Web3Modal } from '@web3modal/react';

import {
    configureChains,
    createConfig,
    useAccount as useWagmiAccount,
    WagmiConfig
} from 'wagmi';

import { mainnet } from 'wagmi/chains';
const chains = [mainnet];

const wcProjectId = '25a0426542f920a78bce582f2f50f56f';

// Wagmi client
const { publicClient } = configureChains(chains, [
    w3mProvider({ projectId: wcProjectId })
]);
const wagmiConfig = createConfig({
    connectors: w3mConnectors({
        chains,
        projectId: wcProjectId
    }),
    publicClient
});

// Web3Modal Ethereum Client
const ethereumClient = new EthereumClient(wagmiConfig, chains);

function RemWalletProvider({ children }) {
    const history = useHistory();
    const location = useLocation();
    const theme = useContext(PoetThemeContext);

    const [lastLoadedRestoreState, setLastLoadedRestoreState] = useState({});

    return (
        <Auth0Provider
            domain={window.__RUNTIME_CONFIG__.AUTH0_DOMAIN}
            clientId={
                theme?.features?.auth0ClientId ??
                window.__RUNTIME_CONFIG__.AUTH0_CLIENT_ID
            }
            redirectUri={window.location.origin}
            audience={window.__RUNTIME_CONFIG__.AUTH0_AUDIENCE}
            scope='https://rem.api.bandwagonfanclub.com/auth/custodial.write'
            useRefreshTokens
            cacheLocation='localstorage'
            onRedirectCallback={appState => {
                setLastLoadedRestoreState(appState || {});
                history.push(appState?.returnPath || location.pathname);
            }}
        >
            <>
                <WagmiConfig config={wagmiConfig}>
                    <WithAuth0
                        {...{
                            lastLoadedRestoreState,
                            setLastLoadedRestoreState,
                            history
                        }}
                    >
                        {children}
                    </WithAuth0>
                </WagmiConfig>

                <Web3Modal
                    projectId={wcProjectId}
                    ethereumClient={ethereumClient}
                />
            </>
        </Auth0Provider>
    );
}

function WithAuth0({
    children,
    lastLoadedRestoreState,
    setLastLoadedRestoreState,
    history
}) {
    const location = useLocation();
    const {
        getAccessTokenSilently,
        error,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        logout,
        user
    } = useAuth0();

    // `undefined` if no web3 wallet is connected.
    const { address: web3Wallet, connector: web3Connector } = useWagmiAccount();

    // How should we display the web3 wallet to the user? Ununsed if there is no
    // connected web3 wallet.
    const displayWeb3Wallet = useDisplayedAddress(web3Wallet);

    // All re-renders of a given RemWalletProvider share the same restore state.
    // This is important since some data loads in asynchronously (for example,
    // when an image input loads in), so if other changes have happened in the
    // mean time, all state changes should impact the same object (otherwise
    // the async change will use an "old" mergeFormState() and update based on
    // old state). Storing the version number as non-memoized state and
    // incrementing when it changes ensures children re-render when the value of
    // the memoized state changes.
    const currentRestoreState = useMemo(() => ({}), []);
    const [restoreStateVersion, setRestoreStateVersion] = useState(0);

    function mergeFormState(form) {
        Object.assign(currentRestoreState, form);
        setRestoreStateVersion(restoreStateVersion + 1);
    }

    const [bearerToken, setBearerToken] = useState();
    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                const token = await getAccessTokenSilently({
                    audience: window.__RUNTIME_CONFIG__.AUTH0_AUDIENCE,
                    scope: 'https://rem.api.bandwagonfanclub.com/auth/custodial.write'
                });

                setBearerToken(token);
            }
        })().catch(console.error);
    }, [getAccessTokenSilently, isAuthenticated]);

    const remRequest = useMemo(
        () => (document, variables) =>
            graphqlRequest({
                url: `${window.__RUNTIME_CONFIG__.REACT_APP_REMINISCERATOR_ENDPOINT}/graphql`,
                document,
                variables,
                requestHeaders: {
                    ...(bearerToken && {
                        Authorization: `Bearer ${bearerToken}`
                    })
                }
            }),
        [bearerToken]
    );

    const [socialWeb3Wallet, setSocialWeb3Wallet] = useState();
    const [supply, setSupply] = useState();
    useEffect(() => {
        (async () => {
            if (web3Wallet) {
                setSocialWeb3Wallet(null);
                const {
                    userInfo: { supplyLimit }
                } = await remRequest(
                    `
                        query UserInfo($userWallet: String!) {
                            userInfo(userWallet: $userWallet) {
                               supplyLimit
                            }
                        }
                    `,
                    {
                        userWallet: web3Wallet
                    }
                );
                setSupply(supplyLimit);
            } else if (user && bearerToken) {
                const {
                    userInfo: { resolvedAddress, supplyLimit }
                } = await remRequest(
                    `
                        query UserInfo($userId: String!) {
                            userInfo(userId: $userId) {
                                resolvedAddress
                                supplyLimit
                            }
                        }
                    `,
                    {
                        userId: user.sub
                    }
                );
                setSupply(supplyLimit);
                setSocialWeb3Wallet(resolvedAddress);
            }
        })();
    }, [
        bearerToken,
        remRequest,
        setSocialWeb3Wallet,
        user,
        setSupply,
        web3Wallet
    ]);

    let urlWallet;
    if (user) {
        urlWallet = createUrlWallet(user);
    } else {
        urlWallet = web3Wallet;
    }

    const actingWallet = web3Wallet ?? socialWeb3Wallet;
    const displayName =
        displayWeb3Wallet && displayWeb3Wallet !== '(Unknown wallet)'
            ? displayWeb3Wallet
            : urlWallet
            ? urlWallet.substring(0, 6) +
              '...' +
              urlWallet.substring(urlWallet.length - 4)
            : 'Not logged in';

    return error ? (
        <p>{error}</p>
    ) : isLoading ? null : (
        <RemWalletContext.Provider
            value={{
                actingWallet,
                auth0Login: web3Wallet ? undefined : user,
                available: isAuthenticated || !!web3Wallet,
                clearState: () => {
                    for (const key of Object.keys(currentRestoreState)) {
                        delete currentRestoreState[key];
                    }
                    setRestoreStateVersion(restoreStateVersion + 1);
                    setLastLoadedRestoreState({});
                },
                displayName,
                formState: lastLoadedRestoreState,
                headers: {
                    ...(bearerToken && {
                        Authorization: `Bearer ${bearerToken}`
                    })
                },
                isConnectedUser(pUrlWallet) {
                    if (!pUrlWallet) {
                        return false;
                    }

                    return pUrlWallet.startsWith('u_')
                        ? pUrlWallet === urlWallet
                        : pUrlWallet.endsWith('.eth')
                        ? pUrlWallet.toLowerCase() ===
                          displayWeb3Wallet?.toLowerCase()
                        : pUrlWallet.toLowerCase() ===
                              web3Wallet?.toLowerCase() ||
                          pUrlWallet.toLowerCase() ===
                              socialWeb3Wallet?.toLowerCase();
                },
                loginWithState: async () => {
                    await loginWithRedirect({
                        appState: {
                            ...currentRestoreState,

                            // All Auth0 can do is get us back to our SPA. Then we'll read
                            // this and take over to get us to the exact right place...
                            returnPath: location.pathname
                        }
                    });
                },
                logout: async () => {
                    if (web3Wallet) {
                        await ethereumClient.disconnect();
                        await history.push('/');
                    } else {
                        await logout({
                            returnTo: window.location.origin
                        });
                    }
                },
                mergeFormState,
                remRequest,
                urlWallet,
                web3Connector,
                web3Wallet,
                userSupplyLimit: supply
            }}
        >
            {children}
        </RemWalletContext.Provider>
    );
}

function createUrlWallet(user) {
    if (!user) {
        return null;
    }
    const idBuffer = Buffer.from(user.sub, 'utf8');
    const encoded = bs58.encode(idBuffer);
    return 'u_' + encoded;
}

export default RemWalletProvider;
