import graphqlRequest from './graphql-request';
import lodash from 'lodash';

export default async function getNfts(variables) {
    const { res, error } = await graphqlRequest(
        `
        query GetNfts(
          $metadataPredicates: [Predicate!]!
          $ownerAddress: String
          $userId: String
          $first: Int!
          $after: String!
          $sort: SortOperation
        ) {
          nfts(
            metadataPredicates: $metadataPredicates
            ownerAddress: $ownerAddress
            userId: $userId
            first: $first
            after: $after
            sortOperation: $sort
          ) {
            success
            edges {
              node {
                tokenId
                contractAddress
                metadataUri
                metadata
                ownerAddress
                ownerAddresses
              }
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
              startCursor
              endCursor
            }
          }
        }
      `,
        variables
    );

    return {
        res: lodash.get(res, 'nfts', {}),
        error
    };
}
