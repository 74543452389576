export default function Image(props) {
    const { image, setImages } = props;

    return (
        <div className='relative flex justify-center items-center'>
            <img
                onClick={() => {
                    setImages([]);
                }}
                className='rounded border border-gray-200 opacity-100 hover:bg-gray-400 cursor-pointer'
                src={image}
                alt='NFT'
            />
        </div>
    );
}
