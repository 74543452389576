import graphqlRequest from './graphql-request';

export default async function issue1155(
    contractAddress,
    tokenId,
    captchaToken,
    { toAddress, userId },
    options
) {
    const graphqlResponse = await graphqlRequest(
        `
                mutation Issue1155Nft(
                    $captchaToken: String!
                    $contractAddress: String!
                    $tokenId: Int!
                    $toAddress: String
                    $userId: String
                ) {
                    issue1155Nft(
                        captchaToken: $captchaToken
                        contractAddress: $contractAddress
                        tokenId: $tokenId
                        toAddress: $toAddress
                        userId: $userId
                    ) {
                        success
                        tokenId
                    }
                }
            `,
        { captchaToken, contractAddress, tokenId, toAddress, userId },
        options
    );

    const result = graphqlResponse?.res?.issue1155Nft;
    const error = graphqlResponse.error;

    if (!result) {
        throw new Error(error);
    }

    return result.txHash;
}
