import FeaturedPoetInfo from './FeaturedPoetInfo.jsx';
import './FeaturedPoetInfoCard.css';

export default function FeaturedPoetInfoCard({
    className = '',
    contract,
    token,
    variant = 'row'
}) {
    return (
        <div className={`FeaturedPoetInfoCard ${className}`}>
            <FeaturedPoetInfo
                className={`FeaturedPoetInfo--${variant}`}
                contract={contract}
                token={token}
            />
        </div>
    );
}
