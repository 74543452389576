import caratLeft from './carat-left.png';
import caratRight from './carat-right.png';
import deleteImage from './delete-image.png';
import Loader from '../Loader';

import { Carousel as CarouselBase } from 'react-responsive-carousel';
import { IconButton } from '@mui/material';
import { useState } from 'react';

import './Carousel.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

export default function Carousel(props) {
    const { allowDelete = false, onDelete = image => {} } = props;

    const [selectedIndex, setSelectedIndex] = useState(0);

    function slides() {
        let slides = [];
        let index = 0;
        for (const child of props.children) {
            const childIndex = index;
            slides.push(
                <div key={`${index}`} className='Carousel-slide'>
                    <div className='Carousel-slideContentContainer'>
                        {allowDelete && (
                            <IconButton
                                className='Carousel-deleteContent'
                                onClick={() => {
                                    onDelete(child.props.src);

                                    if (
                                        childIndex ===
                                        props.children.length - 1
                                    ) {
                                        setSelectedIndex(
                                            props.children.length - 2
                                        );
                                    }
                                }}
                            >
                                <img src={deleteImage} alt='Remove image' />
                            </IconButton>
                        )}
                        {child ? child : <Loader className='Carousel-loader' />}
                    </div>
                </div>
            );
            index++;
        }
        return slides;
    }

    return (
        <>
            <CarouselBase
                className='Carousel-desktop'
                centerSlidePercentage={40}
                onChange={setSelectedIndex}
                selectedItem={selectedIndex}
                showThumbs={false}
                {...carouselCustomization}
            >
                {slides()}
            </CarouselBase>
            <CarouselBase
                className='Carousel-mobile'
                centerSlidePercentage={85}
                onChange={setSelectedIndex}
                selectedItem={selectedIndex}
                showThumbs={false}
                {...carouselCustomization}
            >
                {slides()}
            </CarouselBase>
        </>
    );
}

var carouselCustomization = {
    centerMode: true,
    renderArrowPrev: (onClickHandler, hasPrev, label) =>
        hasPrev && (
            <button
                type='button'
                onClick={onClickHandler}
                title={label}
                className='Carousel-tokenNavControl Carousel-previousTokenControl'
            >
                <img src={caratLeft} alt='Previous Token' />
            </button>
        ),
    renderArrowNext: (onClickHandler, hasNext, label) =>
        hasNext && (
            <button
                type='button'
                onClick={onClickHandler}
                title={label}
                className='Carousel-tokenNavControl Carousel-nextTokenControl'
            >
                <img src={caratRight} alt='Next Token' />
            </button>
        ),
    showStatus: false
};
